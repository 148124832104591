<div class="container">
  <h1 class="title">Productos de limpieza por mayor, fabrica de productos de limpieza</h1>
</div>
<div class="container">
  <div class="bannercategoria left">
    <a routerLink="/Limpieza">
      <img width="831" height="176" [ngClass]="'lazyload'" [attr.data-src]="environment.APP_API_BASE+'storage/images/web_bnncategoria-limpieza.webp'" alt="Limpieza">
    </a>
  </div>
  <div class="bannercategoria right">
    <a routerLink="/Bazar">
      <img width="831" height="176" [ngClass]="'lazyload'" [attr.data-src]="environment.APP_API_BASE+'storage/images/web_bnncategoria-bazar.webp'" alt="Bazar">
    </a>
  </div>
  <div class="bannercategoria left">
    <a routerLink="/Textil">
      <img width="831" height="176" [ngClass]="'lazyload'" [attr.data-src]="environment.APP_API_BASE+'storage/images/web_bnncategoria-textil.webp'" alt="Textil">
    </a>
  </div>
  <div class="bannercategoria right">
    <a routerLink="/Liquidos">
      <img width="831" height="176" [ngClass]="'lazyload'" [attr.data-src]="environment.APP_API_BASE+'storage/images/web_bnncategoria-liquidos.webp'" alt="Liquidos">
    </a>
  </div>
  <div class="bannercategoria left">
    <a routerLink="/Jardin y riego">
      <img width="831" height="176" [ngClass]="'lazyload'" [attr.data-src]="environment.APP_API_BASE+'storage/images/web_bnncategoria-jardinyriego.webp'" alt="Jardin y riego">
    </a>
  </div>
  <div class="bannercategoria right">
    <a routerLink="/Profesional">
      <img width="831" height="176" [ngClass]="'lazyload'" [attr.data-src]="environment.APP_API_BASE+'storage/images/web_bnncategoria-profesional.webp'" alt="Profesional">
    </a>
  </div>
  <div class="bannercategoria left">
    <a routerLink="/Mas productos">
      <img width="831" height="176" [ngClass]="'lazyload'" [attr.data-src]="environment.APP_API_BASE+'storage/images/web_bnncategoria-+productos.webp'" alt="Mas productos">
    </a>
  </div>
</div>