<div class="row" style="background: #f8f8f8; margin: 0;">
  <div class="container">
      <div class="row" style="padding: 60px 45px;">
          <div class="tab">
            <div class="tab__header" style="    color: #ffffff;
            border-bottom: 5px solid #057aff !important;
            padding-top: 70px;
            padding-bottom: 10px;
            position: relative;">
              <p style="    color: #057aff;
              position: absolute;
              bottom: 10px;
              left: 20px;
              font-size: 20px;
              font-weight: bold;
              width: calc(100% - 625px);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;">Confirmación de cuenta</p>
            </div>
            <div class="tab__content" style="    background-color: #ffffff;">
              <div class="content" style="padding: 40px;">
                <p style="font-size: 16px; font-weight: bold;">
                  <img width="50" height="40" *ngIf="loading" id="rel2" [ngClass]="'lazyload'" data-src="./assets/images/loader/ajax-loader.gif"/><br>
                  {{respuesta}}
                </p>            
              </div>
            </div>
          </div>
      </div>
  </div>
</div>