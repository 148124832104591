import { isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, UpdateAvailableEvent } from "@angular/service-worker";

@Injectable({
  providedIn: 'root'
})
export class PromptUpdateService {

  constructor(
    updates: SwUpdate,
    @Inject(PLATFORM_ID) platformId: Object,
    private snackbar: MatSnackBar,
  ) {
    if (!isPlatformServer(platformId)) {
      updates.available.subscribe(event => {
        // console.log(event);

        const snack = this.snackbar.open(
          'Nuevo contenido disponible!'+"\n"+'Por favor recargá la página.',
          'Recargar',
          {
            horizontalPosition: "end",
            verticalPosition: "bottom",
            panelClass: ['pwa-snackbar']
          }
        );

        snack.onAction().subscribe(() => {
          updates.activateUpdate().then(() => window.location.href = window.location.href);
        });
      });
    }
  }
}
