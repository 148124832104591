import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AutenticacionService } from '../autenticacion.service';
import { Carrito } from '../data';
import { MenuService } from '../menu.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { FacebookPixelService } from '../facebook-pixel.service';
import { cliente, Dato, SharedService } from '../shared.service';
import { environment } from 'src/environments/environment';

const COMPRA: Carrito = {
  fecha: 'hoy',
  list: [
    {texto: 'Escobas', url: '', precio: '19,90'},
    {texto: 'Escobas', url: '', precio: '19,90'},
    {texto: 'Escobas', url: '', precio: '19,90'},
    {texto: 'Escobas', url: '', precio: '19,90'},
    {texto: 'Escobas', url: '', precio: '19,90'},
    {texto: 'Escobas', url: '', precio: '19,90'},
    {texto: 'Escobas', url: '', precio: '19,90'},
    {texto: 'Escobas', url: '', precio: '19,90'},
  ],
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  @Input() greetMessage!: Dato[];
  @Input() headerStatus!: number;

  @ViewChild('inputCantidadHeader') inputCantidadHeader!: ElementRef;
  inputSubHeader!: Subscription;
  @ViewChild('inputCantidadHeaderVerde') inputCantidadHeaderVerde!: ElementRef;
  inputSubHeaderVerde!: Subscription;

  _existDesktop: boolean = false;
  _existMobile: boolean = false;

  menuToggle = false;
  menuAbierto: boolean = false;
  CompraList = COMPRA;
  LinkList: any[] = [];
  MenuFilter = '/filter';
  MenuList: any[] = [];
  MenuTitle = 'Limpieza';
  MenuClass = 'header__filter--hover';
  headerPosition = '';
  SideState =     'menuSidebar';
  SideStateIcon = '';
  UserLog = false;
  carritoStatus = false;
  SearchFocus = '';
  SearchModel = '';
  resultados: any;
  config: any;
  // user Data
  UserName!: string;
  UserJob!: string;
  isMenuOpen: boolean = false;

  menuOpened() {
    this.isMenuOpen = true;
  }

  menuClosed() {
    this.isMenuOpen = false;
  }

  actualRoute = '/';

  menuSelectedItem = 0;

  popup = {
    cuenta: false,
    compra: false,
  };
  popup2 = {
    cuenta: false,
    compra: false,
  };
  message!: string;

  selectedItem: any = undefined;
  selectItem($item: any) {
    if (this.selectedItem) {
      this.selectedItem.show = false;
    }
    this.selectedItem = $item;
    this.selectedItem.show = true;
  }

  cuenta = [
    { texto: 'Mi cuenta', id: 0, admin: false },
    { texto: 'Mis datos', id: 1, admin: false },
    { texto: 'Mis frecuentes', id: 2, admin: false },
    { texto: 'Ultimas compras', id: 3, admin: false },
    { texto: 'Cambiar representado', id: 5, admin: true},
    { texto: 'Cerrar sesión', id: 4, admin: false },
  ];

  representarHeaderStatus: boolean = false;
  representarHeaderLoading: boolean = true;
  cuentasRepresentar: any[] = [];
  cantCuentasRepresentar = 0;
  cuentaRespuesta: string = '';
  filterCantidad: number = 20;
  filterBusqueda!: string;
  cuentaSeleccionada: any;

  seleccionarCuenta(cuenta: any) {
    this.cuentaSeleccionada = Object.assign({}, cuenta);
  }
  headerCuentaLink(id: number) {
    if (id === 5) {
      this.representarHeaderLoading = true;
      this.auth.get('cliente/getPrimeros')
      .then(($response: any) => {
        this.cuentasRepresentar = $response.response;
        this.cantCuentasRepresentar = $response.cant;
        this.representarHeaderLoading = false;
        this.data.toggleRepresentarHeader();
      })
      .catch(($error: any) => {
        this.representarHeaderLoading = false;
        this.data.log('clientegetall error header', $error);
      });
    } else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['/cuenta'], { queryParams: {tab: id}}));
    }
  }
  cerrarRepresentarCuenta() {
    this.data.toggleRepresentarHeader();
  }
  public cambiarCantFiltro(event: any): void {
    this.filterCantidad = event.target.value;
  }
  enterBusquedaCuentas(event: any) {
    if (event.keyCode == 13) {
      this.filtrarCuentas();
    }
  }
  filtrarCuentas() {
    this.representarHeaderLoading = true;

    const body = new URLSearchParams();

    body.set('like_filter', this.filterBusqueda);
    body.set('cant_filter', this.filterCantidad.toString(10));

    this.auth.post('cliente/getFiltrados', body)
    .then(($response: any) => {
      this.cuentasRepresentar = $response.body.response;
      this.representarHeaderLoading = false;
    })
    .catch(($error: any) => {
      this.representarHeaderLoading = false;
      this.data.log('clientegetfiltrados error app', $error);
    });
  }
  representarCuenta(cuenta: any) {
    this.cuentaRespuesta = 'Esperando respuesta...';
    if (cuenta) {
      const body = new URLSearchParams();
      body.set('codigo_cliente', cuenta.codigo_cliente);
      this.auth.post('auth/admin/representar', body)
        .then(($response: any) => {
          this.cuentaRespuesta = $response.body.response;
          setTimeout(() => {
            const login = this.auth.localGet('login');
            login.token = $response.body.token;
            this.auth.localSet('login', login);
            this.auth.tokenUpdate($response.body.token);
            this.auth.get('cliente/datos')
              .then(($response) => {
                // Client only code.
                if (this.clientOnlyRun) {
                  this.auth.localSet('user', $response.response as cliente);
                  this.representarCancel();
                  // window.location.reload(true); // forceReload deprecated
                  window.location.href = '/';
                }
              })
              .catch(($error: any) => this.data.log('representarcuenta error header', $error));
          }, 1000);
        })
        .catch(($error: any) => {
          this.cuentaRespuesta = $error.error.response;
        });
    }
  }
  representarCancel() {
    this.data.toggleRepresentarHeader();
  }
  texto_busqueda: string = '';
  ResultadoBusqueda: any;
  enterBusqueda(event: any) {
    if (event.keyCode == 13) {
      this.buscarTexto();
    }
  }
  clickBusqueda(item: any) {
    const ruta = '/articulo/' + ((item.categorias && item.categorias.length > 0) ? item.categorias[0].nombre.split(' ').join('-') : '') + '/' + item['id'];
    this.router.navigate([ruta]);
    this.cerrarBusqueda();

  }
  buscarPalabra(event: any) {
    if (event.target.value.length >= 3) {
      const body = new URLSearchParams();
      body.set('frase', this.texto_busqueda);
      this.auth.post('producto/busqueda', body)
      .then(($response: any) => {
          this.ResultadoBusqueda = $response.body.response.slice(0, 6);
      })
      .catch(($error: any) => {
        this.data.log('error buscarPalabra header:', $error);
      });
    }
  }
  buscarTexto() {
    this.texto_busqueda = this.texto_busqueda.replaceAll('/', ' ');
    if (environment.production) {
      this.googleAnalyticsService.search(this.texto_busqueda);
      this.facebookPixelService.search(this.texto_busqueda);
    }
    let arraySecciones = [
      'ofertas',
      'novedades'
    ];
    let arrayFamilias = [
      'ofertas',
      'novedades',
      'limpieza',
      'bazar',
      'textil',
      'liquidos', 'líquidos',
      'jardin y riego', 'jardín y riego',
      'profesional',
      'mas productos', 'más productos'
    ]

    if (arraySecciones.indexOf(this.texto_busqueda.toLowerCase()) !== -1) {
      let toUrl = this.texto_busqueda.toLowerCase();
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => {
        this.router.navigate(['/' + toUrl]);
      });
    } else if (arrayFamilias.indexOf(this.texto_busqueda.toLowerCase()) !== -1) {
      let toUrl = this.texto_busqueda.charAt(0).toUpperCase() + this.texto_busqueda.slice(1).toLowerCase();
      toUrl = toUrl.replaceAll('á', 'a');
      toUrl = toUrl.replaceAll('é', 'e');
      toUrl = toUrl.replaceAll('í', 'i');
      toUrl = toUrl.replaceAll('ó', 'o');
      toUrl = toUrl.replaceAll('ú', 'u');
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => {
        this.router.navigate(['/' + toUrl]);
      });
    } else {
      let toUrl = this.texto_busqueda;
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => {
        this.router.navigate(['/busqueda/' + toUrl]);
      });
    }

    this.cerrarBusqueda();
  }
  cerrarBusqueda() {
    this.ResultadoBusqueda = [];
    this.texto_busqueda = '';
  }

  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  constructor(
    private menu: MenuService,
    private router: Router,
    public data: SharedService,
    private auth: AutenticacionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (typeof window !== typeof undefined) {
      if (window.innerWidth <= 992) {
        this._existDesktop = false;
        this._existMobile = true;
      } else {
        this._existDesktop = true;
        this._existMobile = false;
      }
    } else {
      this._existDesktop = true;
      this._existMobile = true;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }

    this.MenuClass = '';
    this.menu.notifyObservable$.pipe(takeUntil(this.destroy$)).subscribe(($cambio) => {
      if ($cambio) {
        this.closeCompra();
      }
    });
    this.menu.LinkList$.pipe(takeUntil(this.destroy$)).subscribe(($cambio_link: any) => {
      if ($cambio_link) {
        this.LinkList = $cambio_link;
      }

    });
    this.menu.MenuList$.pipe(takeUntil(this.destroy$)).subscribe(($cambio_menu: any) => {
      if ($cambio_menu) {
        this.MenuList = $cambio_menu;
      }
    });
    // Client only code.
    if (this.clientOnlyRun) {
      // subscribing to data on carritoStatus
      this.data.currentCarrito.pipe(takeUntil(this.destroy$)).subscribe(
        (status) => {
          this.carritoStatus = status;
          if (typeof window !== typeof undefined) {
            if ((this.headerStatus > 90 && window.innerWidth > 960) ||
            (this.headerStatus > 0  && window.innerWidth <= 960)) {
              this.popup.compra = status;
            } else {
              this.popup2.compra = status;
            }
          } else {
            this.popup.compra = status;
          }
        },
      );
    }
    // subscribing to data on carritoStatus?? debe ser loginStatus algo asi
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.UserLog = status;
        if (this.data.user) {
          try {
            this.UserName = this.data.user.razonSocial;
            this.UserJob  = this.data.user.categoriaIva;
            this.representado = typeof localStorage !== typeof undefined ? (JSON.parse(localStorage.getItem('login') || '')?.administrativo) : '';
          } catch (e) {
            this.data.log('error userlog header:', e);
          }
        }
      },
    );
    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(($user: any) => {
      if (this.data.user) {
        try {
          this.data.log('user viejo header:', this.data.user.razonSocial);
          this.UserName = $user['razonSocial'];
          this.UserJob  = $user['categoriaIva'];
        }catch (e) {
          this.data.log('error user viejo header:', e);
        }
      }
    });

  }

  ngOnInit() {
    // subscribing to router change
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((val: any) => {
      if (val['url']) {
        if (this.actualRoute !== val['url']) {
          this.actualRoute = (val['url']);
        }
      }
    });

    // subscribing to config change
    this.data.currentConfig.pipe(takeUntil(this.destroy$)).subscribe(
      (configuracion) => {
        this.config = configuracion;
      },
    );

    // subscribing to data on representarHeaderStatus
    this.data.currentHeaderRepresentar.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.representarHeaderStatus = status;
      },
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngAfterViewInit() {
    // Client only code.
    if (this.clientOnlyRun) {
      setTimeout(() => {
        this.inputSubHeader = fromEvent(this.inputCantidadHeader.nativeElement, 'input')
        .pipe(takeUntil(this.destroy$), debounceTime(300)).subscribe(
          () => {
            const body = new URLSearchParams();
            const array = [];
            for (const item of this.data.lista) {
              if (item.cantidad > 0) {
                array.push({id_producto: item.id, cantidad: item.cantidad});
              }
            }
            body.set('lista', JSON.stringify(array));

            this.auth.post('carrito/update_cantidades', body)
            .then(($response) => {
              this.data.log('response carritoupdatecantidades header debounced', $response);
            })
            .catch(($error) => {
              this.data.log('error carritoupdatecantidades header debounced', $error);
            });
          },
        );
        if (this.inputCantidadHeaderVerde) {
          this.inputSubHeaderVerde = fromEvent(this.inputCantidadHeaderVerde.nativeElement, 'input')
          .pipe(takeUntil(this.destroy$), debounceTime(300)).subscribe(
            () => {
              const body = new URLSearchParams();
              const array = [];
              for (const item of this.data.lista) {
                if (item.cantidad > 0) {
                  array.push({id_producto: item.id, cantidad: item.cantidad});
                }
              }
              body.set('lista', JSON.stringify(array));

              this.auth.post('carrito/update_cantidades', body)
              .then(($response) => {
                this.data.log('response carritoupdatecantidades header debounced verde', $response);
              })
              .catch(($error) => {
                this.data.log('error carritoupdatecantidades header debounced verde', $error);
              });
            },
          );
        }
      }, 2000);
    }
  }

  representado: boolean = false;
  // auxiliar value
  lastInput: boolean = false;
  ngOnChanges() {
    if (typeof window !== typeof undefined) {
      if ((this.headerPosition !== 'header--fixed' && this.headerStatus > 90 && window.innerWidth > 960) || (this.headerPosition === 'header--fixed' && this.headerStatus > 0 && window.innerWidth > 960) || (this.headerStatus > 0 && window.innerWidth <= 960)) {
        this.headerPosition = 'header--fixed';
        if (this.popup.cuenta) {
          this.popup2.cuenta = true;
        }
        if (this.popup.compra) {
          this.popup2.compra = true;
        }
        this.popup.cuenta = false;
        this.popup.compra = false;
      } else {
        if (this.popup2.cuenta) {
          this.popup.cuenta = true;
        }
        if (this.popup2.compra) {
          this.popup.compra = true;
        }
        this.headerPosition = 'header--fixed';
        this.popup2.cuenta = false;
        this.popup2.compra = false;
      }
    } else {
      if (this.popup2.cuenta) {
        this.popup.cuenta = true;
      }
      if (this.popup2.compra) {
        this.popup.compra = true;
      }
      this.headerPosition = '';
      this.popup2.cuenta = false;
      this.popup2.compra = false;
    }
  }
  actualIndex: number = 0;
  changeStyle($event: any, itemIndex: any) {
    this.actualIndex = itemIndex;

    if (typeof window !== typeof undefined) {
      if (window.innerWidth > 960) {
        this.MenuClass = $event.type == 'mouseover' ? 'header__filter--hover' : '';
        if (this.actualIndex >= 0) {
          this.MenuList   = this.LinkList[this.actualIndex].links;
          this.MenuTitle  = this.LinkList[this.actualIndex].texto;
        }
      }
    }
  }
  changeStyleMenu($event: any, itemIndex: any) {
    if (typeof window !== typeof undefined) {
      if (window.innerWidth > 960) {
        this.MenuClass = $event.type == 'mouseover' ? 'header__filter--hover' : '';
        if (itemIndex >= 0) {
          this.MenuList   = this.LinkList[this.actualIndex].links;
          this.MenuTitle  = this.LinkList[this.actualIndex].texto;
        } else {
          this.MenuList = [];
        }
      }
    }
  }
  changeStyleClick(itemIndex: any) {
    if (typeof window !== typeof undefined) {
      if (window.innerWidth >  960) {
        this.MenuList   = this.LinkList[itemIndex].links;
        this.MenuTitle  = this.LinkList[itemIndex].texto;
        this.MenuClass = this.menuToggle === false ? 'header__filter--hover' : '';
      }
    }
  }
  itemClicked() {
    this.MenuClass = '';
  }
  closeCompra() {
    this.popup.compra = false;
    this.popup2.compra = false;

    if (typeof window !== typeof undefined) {
      if (window.innerWidth > 960) {
        this.popup.cuenta = false;
        this.popup2.cuenta = false;
      }
    }
  }
  toggle(component: string) {
    if (this.UserLog) {
      if (component === 'cuenta') {
        this.popup.cuenta = !this.popup.cuenta;
        this.popup.compra = false;
      } else {
        this.popup.compra = !this.popup.compra;

        if (this.popup.compra) {
          this.listaResultados = this.greetMessage;
          this.listaOriginal = this.greetMessage;
          this.listaDefault = this.greetMessage.slice();
          this.paginado.init();
          this.paginado.pagina = this.paginado.maximo;
          this.paginado.actualizar();
        }

        this.popup.cuenta = false;
      }
    } else {
      this.data.toggleLoginModal();
    }
  }
  toggle2(component: string) {
    if (this.UserLog) {
      if (component === 'cuenta') {
        this.popup2.cuenta = !this.popup2.cuenta;
        this.popup2.compra = false;
      } else {
        this.popup2.compra = !this.popup2.compra;

        if (this.popup2.compra) {
          this.listaResultados = this.greetMessage;
          this.listaOriginal = this.greetMessage;
          this.listaDefault = this.greetMessage.slice();
          this.paginado.init();
          this.paginado.pagina = this.paginado.maximo;
          this.paginado.actualizar();
        }

        this.popup2.cuenta = false;
      }
    } else {
      this.data.toggleLoginModal();
    }
  }
  carritoVerTodos() {
    this.toggle('compra');
    if (this.greetMessage && this.greetMessage.length > 0) {
      this.router.navigateByUrl('/compra/carrito');
    } else {
      this.router.navigateByUrl('/compra/carrito', {skipLocationChange: true}).then(() => this.router.navigateByUrl('/'));
    }
  }
  carritoVerTodos2() {
    this.toggle2('compra');
    if (this.greetMessage && this.greetMessage.length > 0) {
      this.router.navigateByUrl('/compra/carrito');
    } else {
      this.router.navigateByUrl('/compra/carrito', {skipLocationChange: true}).then(() => this.router.navigateByUrl('/'));
    }
  }
  toggleMenu() {
    this.data.toggleSideBar();
  }


  openMenu() {
    this.menuAbierto = !this.menuAbierto;
  }

  removeMessage(msg: any) {
    const body = new URLSearchParams();
    body.set('id_producto', msg.id);
    this.auth.post('carrito/eliminar_item', body)
    .then(($response) => {
      if (environment.production) {
        this.googleAnalyticsService.removeFromCart({ sku: msg.sku, descripcion: msg.descripcion, categoria: msg.categoria, precio: msg.precio, cantidad: msg.cantidad });
      }
      this.data.log('response carritoeliminaritem header', $response);
      this.data.removeMessage(msg);
      this.listaResultados = this.greetMessage;
      this.listaOriginal = this.greetMessage;
      this.listaDefault = this.greetMessage.slice();
      this.paginado.init(false);
    })
    .catch(($error) => {
      this.data.log('error carritoeliminaritem header', $error);
    });
  }
  listFilter($array: any) {
    if ($array.length > 8) {
      return $array.slice(-8);
    } else {
      return $array;
    }
  }
  focusFunction() {
    this.SearchFocus = 'onSearchFocus';
  }
  focusclickFunction() {
    this.SearchFocus = 'onSearchFocus';
  }
  focusoutFunction() {
    setTimeout(() => {
      this.SearchFocus = '';
      this.cerrarBusqueda();
    }, 1000);
  }
  updatePrecio($precio: any, $cantidad: any): string { // TODO: revisar esto, mientras el popup se muestra esta llamandose en cada tick
    const subtotal = $precio * $cantidad;
    return this.formatMoney(subtotal);
  }
  updateSubtotal(listaCarrito: any): string { // TODO: revisar esto, mientras el popup se muestra esta llamandose en cada tick
    let subtotal = 0;
    listaCarrito.forEach((item: any) => {
      subtotal += item.cantidad * item.precio;
    });
    return this.formatMoney(subtotal);
  }
  formatMoney(n: any, c: any = undefined, d: any = undefined, t: any = undefined) {
    let s;
    let i;
    let j;
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? ',' : d,
    t = t == undefined ? '.' : t,
    s = n < 0 ? '-' : '',
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - +i).toFixed(c).slice(2) : '');
  }

  public revisarCantidad(e: any) {
    if (e.target && parseInt(e.target.value) < parseInt(e.target.min)) {
      e.target.value = e.target.min;
    }
  }

  modoVista: string = 'Paginado';
  listaResultados: any;
  listaOriginal: any;
  listaDefault: any;

  paginado = {
    cantidad: 8,
    pagina: 0,
    maximo: 0,
    original: [],
    init: (cambiaPagina: boolean = true) => {
      if (cambiaPagina) {
        this.paginado.pagina = 1;
      }
      this.paginado.maximo = Math.trunc(this.listaResultados.length / this.paginado.cantidad);
      if (this.listaResultados.length % this.paginado.cantidad) {
        this.paginado.maximo++;
      }
      this.paginado.original = this.listaResultados;
      this.listaResultados = this.paginado.original.slice(0, this.paginado.cantidad);
      if (!cambiaPagina) {
        if (this.paginado.pagina > this.paginado.maximo) {
          this.paginado.pagina = this.paginado.maximo;
        }
        this.paginado.actualizar();
      }
    },
    actualizar: () => {
      this.listaResultados = this.paginado.original.slice(this.paginado.cantidad * (this.paginado.pagina - 1), this.paginado.cantidad * this.paginado.pagina);
    },
    siguiente: () => {
      if (this.paginado.pagina < this.paginado.maximo) { this.paginado.pagina ++; }
      this.paginado.actualizar();
    },
    anterior: () => {
      if (this.paginado.pagina > 1) { this.paginado.pagina --; }
      this.paginado.actualizar();
    },
  };

  getSectionItemLink (item: any) {
    return item.link;
  }
  getSectionItemTexto (item: any) {
    return item.texto;
  }
}
