import { isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from "@angular/service-worker";

@Injectable({
  providedIn: 'root'
})
export class HandleUnrecoverableStateService {
  constructor(
    updates: SwUpdate,
    @Inject(PLATFORM_ID) private platformId: string,
    private snackbar: MatSnackBar,
  ) {
    if (!isPlatformServer(platformId)) {
      updates.unrecoverable.subscribe(event => {
        // console.log(event, event.reason);

        const snack = snackbar.open(
          'Ocurrió un error inesperado.+"\n"+Por favor recargá la página.',
          'Recargar',
          {
            horizontalPosition: "end",
            verticalPosition: "bottom",
            panelClass: ['pwa-snackbar']
          }
        );

        snack.onAction().subscribe(() => {
          window.location.href = window.location.href;
        });
      });
    }
  }
}
