import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { Datos, DatosTransaccion } from '../data';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { FacebookPixelService } from '../facebook-pixel.service';
import { cliente, SharedService } from '../shared.service';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

const datosFis: Datos[] = [
  {
    texto: 'Nombre completo',
    model: 'nombre',
  },
  {
    texto: 'Direccion',
    model: 'direccion',
  },
  {
    texto: 'Localidad',
    model: 'nombre',
  },
  {
    texto: 'Provincia',
    model: 'nombre',
  },
  {
    texto: 'Telefono',
    model: 'nombre',
  },    {
    texto: 'E-mail',
    model: 'nombre',
  },
];
const Items = [
  {
    texto: 'Mi Cuenta',
    model: './assets/images/iconos/Mi-cuenta.png',
    icon: 'user',
  },
  {
    texto: 'Mis Datos',
    model: './assets/images/iconos/Mis-datos.png',
    icon: 'table',
  },
  {
    texto: 'Mis frecuentes',
    model: './assets/images/iconos/Mis-frecuentes.png',
    icon: 'clock-o',
  },
  {
    texto: 'Últimas compras',
    model: './assets/images/iconos/Mis-ultimas-compras.png',
    icon: 'reply',
  },
  {
    texto: 'Cerrar sesión',
    model: './assets/images/iconos/Cerrar-sesion.png',
    icon: 'times-circle',
  },
];

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.scss']
})
export class CuentaComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild('responsable') ngSelectResponsable!: NgSelectComponent;
  @ViewChild('provincia_value') ngSelectProvincia!: NgSelectComponent;
  @ViewChild('provincia_value2') ngSelectProvincia2!: NgSelectComponent;
  @ViewChild('transporte') ngSelectTransporte!: NgSelectComponent;

  formatMoney(n: any, c: any = undefined, d: any = undefined, t: any = undefined) {
    let s;
    let i;
    let j;
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? ',' : d,
    t = t == undefined ? '.' : t,
    s = n < 0 ? '-' : '',
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - +i).toFixed(c).slice(2) : '');
  }
  iva_usuario: string = '';
  currentJustify = 'end';
  datosFiscales = datosFis;
  transaccion: DatosTransaccion;
  sub!: Subscription;
  ListaCompras: any;
  ListaItems = Items;

  item: any;

  desplegar = 'none';
  loginStatus: boolean = false;
  _success = new Subject<string>();
  staticAlertClosed = false;
  successMessage!: string;

  DatosUsuario: cliente = new cliente();
  newRazonSocial:string = '';
  newCuit:string = '';
  newEmail:string = '';

  comprados: any;

  ultimasCompras: any[] = [];
  listadoProductos: any[] = [];
  listadoFrecuentes: any[] = [];

  estadosPedidos: any[] = [
    { value: 0, label: 'Espera confirmación', disabled: false },
    { value: 1, label: 'Próximo a prepararse', disabled: false },
    { value: 2, label: 'En preparación', disabled: false },
    { value: 3, label: 'Preparado', disabled: false },
    { value: 4, label: 'Empaquetado', disabled: false },
    { value: 5, label: 'Despachado', disabled: false },
    { value: 6, label: 'Entregado', disabled: false },
    { value: 7, label: 'Cancelado', disabled: false },
  ]
  estadosPedidosConEnvio: any[] = [
    { value: 0, label: 'Espera confirmación', disabled: false },
    { value: 1, label: 'Próximo a prepararse', disabled: false },
    { value: 2, label: 'En preparación', disabled: false },
    { value: 3, label: 'Preparado', disabled: false },
    { value: 4, label: 'Empaquetado', disabled: false },
    { value: 5, label: 'Despachado', disabled: false },
    { value: 6, label: 'Entregado', disabled: false },
    // { value: 7, label: 'Cancelado', disabled: false },
  ]
  estadosPedidosConRetiro: any[] = [
    { value: 0, label: 'Espera confirmación', disabled: false },
    { value: 1, label: 'Próximo a prepararse', disabled: false },
    { value: 2, label: 'En preparación', disabled: false },
    { value: 3, label: 'Preparado', disabled: false },
    { value: 4, label: 'Empaquetado', disabled: false },
    // { value: 5, label: 'Despachado', disabled: false },
    { value: 6, label: 'Entregado', disabled: false },
    // { value: 7, label: 'Cancelado', disabled: false },
  ]

  /*ultimasCompras = [
    {
      fecha:"",
      lista: [
        {
          cantidad: "",
          descripcion : "",
          precioUnitario: "",
          precioTotal: ""
        }
      ],
      total: ""
  }]*/

  misFrecuentesLink: string = '#';

  provincia_lista: any[] = [];
  transporte_lista: any[] = [];
  initialLista: any[] = [];
  procesando_info: boolean = false;
  procesando_info_entrega: boolean = false;
  procesando_info_error: string = '';
  procesando_info_entrega_error: string = '';
  procesando_info_ok: string = '';
  procesando_info_entrega_ok: string = '';

  _existDesktop: boolean = false;
  _existMobile: boolean = false;

  loadingProductoRecomendado: boolean = false;

  fileDownloadProgressValue: string = '0';

  config: any;

  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  environment: any;

  listaPreciosImagen = true;

  constructor(
    // private _ngZone: NgZone,
    public data:   SharedService,
    private route:  ActivatedRoute,
    private router: Router,
    private http:   HttpClient,
    private auth:   AutenticacionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.environment = environment;

    if (typeof window !== typeof undefined) {
      if (window.innerWidth <= 992) {
        this._existDesktop = false;
        this._existMobile = true;
      } else {
        this._existDesktop = true;
        this._existMobile = false;
      }
    } else {
      this._existDesktop = true;
      this._existMobile = true;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }

    this.transaccion = new DatosTransaccion(0);

    this.http.get(environment.APP_WEB_BASE + 'assets/data/cuenta.json')
    .pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.ListaCompras = res['lista'];
      // this.comprados    = res["comprados"]
      // this.ultimasCompras = res["ultimasCompras"]
    });

    // Client only code.
    if (this.clientOnlyRun) {
      this.auth.get('pedido/getUltimos')
      .then(($response)  => {
        if ($response.response) {
          this.ultimasCompras = $response.response;
          this.data.log('getultimospedidos response cuenta:', this.ultimasCompras, 'ko');
          for (const compra of this.ultimasCompras) {
            compra.items?.forEach((item: any) => {
              this.data?.lista?.forEach((articulo_carrito: any) => {
                if (articulo_carrito.id === item.producto.id) {
                  item.comprado = true;
                }
              });
              if (item.producto['cantPack'] !== '1') {
                item.producto.arrayCants = [];
                for (let i = 0; i < 20; i++) {
                  item.producto.arrayCants[i] = item.cantidad * (i + 1);
                }
                for (let i = 0; i < 50; i++) {
                  item.producto.arrayCants[i + 20] = item.cantidad * (i + 3) * 10;
                }
              }
            })
            const fechaP = new Date(compra['fechaPedido'].date);
            compra['fechaCreacion'] = {
              date: {
                year: fechaP.getFullYear(),
                date: fechaP.getDate(),
                month: fechaP.getMonth() + 1,
              }
            };
          }
        }
      })
      .catch(($error) => {
        this.data.log('getultimospedidos error cuenta:', $error);
        this.router.navigate(['/']);
      });
      this.auth.get('producto/productosMasPedidos')
      .then(($response) => {
        if ($response.response) {
          this.comprados = $response.response;
          this.data.log('productosmaspedidos response cuenta:', this.comprados);
          for (const producto of this.comprados) {
            this.data?.lista?.forEach((articulo_carrito) => {
              if (articulo_carrito.id === producto.id) {
                producto.comprado = true;
              }
            });
            producto.cantidad = producto['cantSugerida'] ? parseInt(producto['cantSugerida']) : 1;
            if (producto['cantPack'] !== '1') {
              producto.cantidad = producto['cantPack'] ? parseInt(producto['cantPack']) : 1;
              producto.arrayCants = [];
              for (let i = 0; i < 20; i++) {
                producto.arrayCants[i] = producto.cantidad * (i + 1);
              }
              for (let i = 0; i < 50; i++) {
                producto.arrayCants[i + 20] = producto.cantidad * (i + 3) * 10;
              }
            }
          }
        }
      })
      .catch(($error) => {
        this.data.log('productosmaspedidos error cuenta:', $error);
        this.router.navigate(['/']);
      });
    }
  }

  public refreshCategoria(value: any): void {
    if (this.DatosUsuario) {
      this.DatosUsuario.codCategoriaIva = value;
    }
  }
  public refreshProvincia(value: any): void {
    if (this.DatosUsuario.domicilio) {
      this.DatosUsuario.domicilio.provincia = value;
    }
  }
  public refreshProvincia2(value: any): void {
    if (this.DatosUsuario.datosEnvio.domicilioEntrega) {
      this.DatosUsuario.datosEnvio.domicilioEntrega.provincia = value;
    }
  }
  public refreshTransporte(value: any): void {
    // this.medioTransporte = value.text
    if (this.DatosUsuario.datosEnvio) {
      this.DatosUsuario.datosEnvio.codigoTransporte = value;
    }
  }
  repetirPregunta($item: any) {
    this.repetirFlag = true;
    this.repetirTemp = $item;
    this.repetirOmitsTemp = $item.items.filter((i: any) => (i.producto.habilitado != '1' || (i.producto.enStock != '1' && i.producto.ventaSoloPorPedido != '1')));
  }
  repetirTemp: any = {};
  repetirOmitsTemp: any = [];
  repetirFlag: boolean = false;
  repetirCancelar() {
    this.repetirTemp = {};
    this.repetirOmitsTemp = [];
    this.repetirFlag = false;
  }
  async repetirCompra() {
    const $item = this.repetirTemp;
    if (this.ultimasCompras.length > 0) {
      if ($item['items'].length > 0) {
        this.auth.get('carrito/eliminar')
        .then(async (result) => {
          this.data.cleanCarrito();
          this.data.updateMessage([]);

          for (const compra of $item['items']) {
            if (compra.producto.habilitado == '1' && (compra.producto.enStock == '1' || compra.producto.ventaSoloPorPedido == '1')) {
              const body = new URLSearchParams();
              body.set('id_producto', compra.producto.id);
              body.set('cantidad', compra.cantidad);
              try {
                const $response = await this.auth.post('carrito/agregar_item', body);
                if (environment.production) {
                  this.googleAnalyticsService.addToCart({ sku: compra.producto.codInterno, descripcion: compra.producto.titulo, categoria: compra.producto.categorias ? compra.producto.categorias[0]?.nombre : undefined, precio: compra.producto.precio, cantidad: compra.cantidad });
                  this.facebookPixelService.addToCart({ sku: compra.producto.codInterno, descripcion: compra.producto.titulo, categoria: compra.producto.categorias ? compra.producto.categorias[0]?.nombre : undefined, precio: compra.producto.precio, cantidad: compra.cantidad });
                }
                this.data.log('response carritoagregaritem cuenta', $response);

                this.data.changeMessage(compra.cantidad ? parseInt(compra.cantidad, 10) : 1,
                compra.producto.titulo, compra.producto.tituloAdicional, compra.producto.precio, parseFloat(compra.producto.precio) * parseInt(compra.producto.cantidad, 10), compra.producto.id,
                compra.producto.codInterno, (compra.producto.categorias && compra.producto.categorias.length > 0) ? compra.producto.categorias[0].nombre : '', compra.producto.cantPack, compra.producto.oferta, compra.producto.urlImagen, compra.producto.ventaSoloPorPedido == '1');
              } catch ($error) {
                this.data.log('error carritoagregaritem cuenta', $error);
                // compra.producto.comprado = true;
              }
            }
          }

          this.router.navigate(['/compra/carrito']);
        })
        .catch(async (error) => {
          this.data.log('carrito/eliminar error cuenta:', error);

          this.data.cleanCarrito();
          this.data.updateMessage([]);

          for (const compra of $item['items']) {
            if (compra.producto.habilitado == '1' && (compra.producto.enStock == '1' || compra.producto.ventaSoloPorPedido == '1')) {
              const body = new URLSearchParams();
              body.set('id_producto', compra.producto.id);
              body.set('cantidad', compra.cantidad);
              try {
                const $response = await this.auth.post('carrito/agregar_item', body);
                if (environment.production) {
                  this.googleAnalyticsService.addToCart({ sku: compra.producto.codInterno, descripcion: compra.producto.titulo, categoria: compra.producto.categorias ? compra.producto.categorias[0]?.nombre : undefined, precio: compra.producto.precio, cantidad: compra.cantidad });
                  this.facebookPixelService.addToCart({ sku: compra.producto.codInterno, descripcion: compra.producto.titulo, categoria: compra.producto.categorias ? compra.producto.categorias[0]?.nombre : undefined, precio: compra.producto.precio, cantidad: compra.cantidad });
                }
                this.data.log('response carritoagregaritem cuenta', $response);

                this.data.changeMessage(compra.cantidad ? parseInt(compra.cantidad, 10) : 1,
                compra.producto.titulo, compra.producto.tituloAdicional, compra.producto.precio, parseFloat(compra.producto.precio) * parseInt(compra.producto.cantidad, 10), compra.producto.id,
                compra.producto.codInterno, (compra.producto.categorias && compra.producto.categorias.length > 0) ? compra.producto.categorias[0].nombre : '', compra.producto.cantPack, compra.producto.oferta, compra.producto.urlImagen, compra.producto.ventaSoloPorPedido == '1');
              } catch ($error) {
                this.data.log('error carritoagregaritem cuenta', $error);
                // compra.producto.comprado = true;
              }
            }
          };

          this.router.navigate(['/compra/carrito']);
        });
      }
    }
  }
  closeRepetir(event: any) {
    if (event.target.className === 'modal__container') {
      this.repetirCancelar();
    }
  }
  ngOnInit() {
    this.data.updatePageTitle();
    setTimeout(() => this.staticAlertClosed = true, 5000);

    this.loadingProductoRecomendado = true;
    // subscribing to config change
    this.data.currentConfig.pipe(takeUntil(this.destroy$)).subscribe(
      (configuracion) => {
        this.config = configuracion;

        if (this.config.idProductoRecomendado && this.config.idProductoRecomendado > 0) {
          // Client only code.
          if (this.clientOnlyRun) {
            this.auth.get('producto/detalles/' + this.config.idProductoRecomendado)
            .then(($response) => {
              if ($response && $response.status) {
                this.item = $response.response;
              }
              this.loadingProductoRecomendado = false;
            })
            .catch(($error) => {
              this.data.log('getdetalleproductorecomendado error cuenta', $error);
              this.loadingProductoRecomendado = false;
            });
          } else {
            this.loadingProductoRecomendado = false;
          }
        } else {
          this.loadingProductoRecomendado = false;
        }
      },
    );
    this._success.pipe(takeUntil(this.destroy$)).subscribe((message) => this.successMessage = message);
    this._success.pipe(takeUntil(this.destroy$), debounceTime(5000)).subscribe(() => this.successMessage = '');

    this.sub = this.route
    .queryParams
    .pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.transaccion.cambio(+params['tab'] || 0);
    });
    // subscribing to data on loginStatus
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.loginStatus = status;
      },
    );
    // Esto se está llamando dos veces seguidas, no se por que aun (cold observer? que deberia ser hot?)
    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(($user: any) => {
      if ($user) {
        this.DatosUsuario = $user;

        this.newRazonSocial = this.DatosUsuario.razonSocial;
        this.newCuit = this.DatosUsuario.cuit;
        this.newEmail = this.DatosUsuario.email;

        if (this.DatosUsuario.datosEnvio) {
          this.DatosUsuario.datosEnvio.entregaLunes = this.DatosUsuario.datosEnvio.entregaLunes && this.DatosUsuario.datosEnvio.entregaLunes === '1' ? true : false;
          this.DatosUsuario.datosEnvio.entregaMartes = this.DatosUsuario.datosEnvio.entregaMartes && this.DatosUsuario.datosEnvio.entregaMartes === '1' ? true : false;
          this.DatosUsuario.datosEnvio.entregaMiercoles = this.DatosUsuario.datosEnvio.entregaMiercoles && this.DatosUsuario.datosEnvio.entregaMiercoles === '1' ? true : false;
          this.DatosUsuario.datosEnvio.entregaJueves= this.DatosUsuario.datosEnvio.entregaJueves && this.DatosUsuario.datosEnvio.entregaJueves === '1' ? true : false;
          this.DatosUsuario.datosEnvio.entregaViernes = this.DatosUsuario.datosEnvio.entregaViernes && this.DatosUsuario.datosEnvio.entregaViernes === '1' ? true : false;
          this.DatosUsuario.datosEnvio.entregaSabado = this.DatosUsuario.datosEnvio.entregaSabado && this.DatosUsuario.datosEnvio.entregaSabado === '1' ? true : false;
        }

        new Promise(($acepto, $rechazo) => {
          this.auth.get('public/cliente/envio/getAll').then((result) => {
            if (result.responseT) {
              this.transporte_lista = [];
              result.responseT?.forEach((transporte: any) => {
                this.transporte_lista.push({
                  id: transporte.codigo,
                  text: transporte.nombre,
                });
              });
            }
            if (result.responseP) {
              this.provincia_lista = [];
              result.responseP?.forEach((provincia: any) => {
                this.provincia_lista.push({
                  id: provincia.codigo,
                  text: provincia.nombre,
                });
              });
            }

            $acepto('ok');
          }).catch((error) => $rechazo(error));
        })
        .then(($respuesta) => {
          this.data.log('userenviogetall response cuenta:', 'okerso', this.transporte_lista, this.initialLista);
          if (this.transaccion.paso === 1) {
            setTimeout(() => {
              if (this.DatosUsuario.codCategoriaIva) {
                this.seleccionariva(this.ngSelectResponsable, this.DatosUsuario.codCategoriaIva);
              }
              if (this.DatosUsuario.domicilio.provincia) {
                this.seleccionarprovincia(this.ngSelectProvincia, this.DatosUsuario.domicilio.provincia);
              }
              if (this.DatosUsuario.datosEnvio.domicilioEntrega.provincia) {
                this.seleccionarprovincia2(this.ngSelectProvincia2, this.DatosUsuario.datosEnvio.domicilioEntrega.provincia);
              }
              if (this.DatosUsuario.datosEnvio.nombreTransporte) {
                this.seleccionartransporte(this.ngSelectTransporte, this.DatosUsuario.datosEnvio.nombreTransporte);
              }
            }, 500);
          }
        })
        .catch(($error) => {
          this.data.log('public/cliente/envio/getAll error cuenta:', this.transporte_lista, this.initialLista);
          this.data.log('oninit error cuenta:', $error);
        });
        if ($user && $user['c'] === '1') {
          this.iva_usuario = 'LOS PRECIOS SON UNITARIOS Y ESTÁN SUJETOS A SU CONDICIÓN HABITUAL';
        } else {
          if ($user) {
            switch ($user['codCategoriaIva']) {
              case 'CF': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
              case 'INR': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
              case 'RS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
              case 'RSS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
              case 'RI': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS NO INCLUYEN IVA'; break;
              case 'EX': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
              case 'PCE': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
              case 'PCS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
              case 'EXE': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS SON FINALES'; break;
              case 'SNC': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
              default: this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS NO INCLUYEN IVA';
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.sub.unsubscribe();
  }

  alertClicked() {
    this.successMessage = '';
    this.data.toggleCarritoShow();
  }

  cambioTab(i: number) {
    this.transaccion.cambio(i);
    if (i === 1) {
      setTimeout(() => {
        if (this.DatosUsuario.codCategoriaIva) {
          this.seleccionariva(this.ngSelectResponsable, this.DatosUsuario.codCategoriaIva);
        }
        if (this.DatosUsuario.domicilio.provincia) {
          this.seleccionarprovincia(this.ngSelectProvincia, this.DatosUsuario.domicilio.provincia);
        }
        if (this.DatosUsuario.datosEnvio.domicilioEntrega.provincia) {
          this.seleccionarprovincia2(this.ngSelectProvincia2, this.DatosUsuario.datosEnvio.domicilioEntrega.provincia);
        }
        if (this.DatosUsuario.datosEnvio.nombreTransporte) {
          this.seleccionartransporte(this.ngSelectTransporte, this.DatosUsuario.datosEnvio.nombreTransporte);
        }
      }, 500);
    }
  }
  public seleccionariva($herramienta: any, $codigo: any) {
    if ($herramienta) {
      const item = $herramienta.itemsList._items.find(($item: any) => $item.value === $codigo);
      if (item) {
        $herramienta.select(item);
      }
    }
  }
  public seleccionarprovincia($herramienta: any, $codigo: any) {
    if ($herramienta) {
      const item = $herramienta.itemsList._items.find(($item: any) => $item.value === $codigo);
      if (item) {
        $herramienta.select(item);
      }
    }
  }
  public seleccionarprovincia2($herramienta: any, $codigo: any) {
    if ($herramienta) {
      const item = $herramienta.itemsList._items.find(($item: any) => $item.value === $codigo);
      if (item) {
        $herramienta.select(item);
      }
    }
  }
  public seleccionartransporte($herramienta: any, $codigo: any) {
    if ($herramienta) {
      const item = $herramienta.itemsList._items.find(($item: any) => $item.label.trim() === $codigo);
      if (item) {
        $herramienta.select(item);
      }
    }
  }
  closeFull(event: any) {
    if (event.target.className === 'modal__container') {
      this.transaccion.cambio(0);
    }
  }
  closeSession() {
    // Client only code.
    if (this.clientOnlyRun) {
      this.auth.desacreditar();
      window.location.href = '/';
    }
  }
  newMessage(msg: any) {
    if (this.loginStatus === true) {
      if (msg.cantidad) {
        if ((+msg.cantidad % +msg.cantPack === 0 && +msg.cantidad > +msg.cantMinima) || (+msg.cantMinima === +msg.cantidad)) {
          const body = new URLSearchParams();
          body.set('id_producto', msg.id);
          body.set('cantidad', msg.cantidad);
          this.auth.post('carrito/agregar_item', body)
          .then(($response) => {
            if (environment.production) {
              this.googleAnalyticsService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
              this.facebookPixelService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
            }
            this.data.log('response carritoagregaritem cuenta', $response);
            const response = this.data.addMessage(msg);
            if (response.value) {
              this._success.next(response.text);
            }
          })
          .catch(($error) => {
            this.data.log('error carritoagregaritem cuenta', $error);
            this._success.next(`Ya se encuentra en el Carrito!`);
            msg.comprado = true;
          });
        } else {
          msg['incompleto'] = true;
        }
      }
    } else {
      this.data.toggleLoginModal();
    }
  }
  removeMessage(msg: any) {
    if (this.loginStatus === true) {
      const body = new URLSearchParams();
      body.set('id_producto', msg.id);
      this.auth.post('carrito/eliminar_item', body)
      .then(($response) => {
        if (environment.production) {
          this.googleAnalyticsService.removeFromCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
        }
        this.data.log('response carritoeliminaritem compra', $response);
        this.data.removeMessage(msg);
        msg.comprado = false;
      })
      .catch(($error) => {
        this.data.log('error carritoeliminaritem compra', $error);
      });
    } else {
      this.data.toggleLoginModal();
    }
  }
  newMessageFromPedido(item: any) {
    if (this.loginStatus === true) {
      item.comprado = true; // TODO: Podriamos cambiar este flag para este item en todas las ultimas compras, ahora cambia solo para la actual
      this.newMessage({ ...item.producto, cantidad: item.cantidad })
    } else {
      this.data.toggleLoginModal();
    }
  }
  removeMessageFromPedido(item: any) {
    if (this.loginStatus === true) {
      item.comprado = false; // TODO: Podriamos cambiar este flag para este item en todas las ultimas compras, ahora cambia solo para la actual
      this.removeMessage({ ...item.producto, cantidad: item.cantidad })
    } else {
      this.data.toggleLoginModal();
    }
  }

  public fileFormatSelector: boolean = false;

  toggleFileFormat() {
    this.fileFormatSelector = !this.fileFormatSelector;
  }

  descargarLista() {
    if (this.fileFormatSelector) {
      this.descargarListaPDF();
    } else {
      this.descargarListaXLSX();
    }
  }

  descargarListaFrecuentes() {
    if (this.fileFormatSelector) {
      this.descargarListaFrecuentesPDF();
    } else {
      this.descargarListaFrecuentesXLSX();
    }
  }

  async descargarListaXLSX() {
    this.fileDownloadProgressValue = '0';

    for (const key in document.querySelectorAll('#loaderFile')) {
      if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
        const element = document.querySelectorAll('#loaderFile')[key];
        (element as HTMLElement).style.display = 'flex';
        (element as HTMLElement).style.flexDirection = 'column';
      }
    }
    this.auth.get('producto/listaPrecios')
    .then(async ($response: any)  => {
      if ($response.response) {
        this.listadoProductos = $response.response;
        let listadoProductosOrdenados: any[] = [];
        for (const producto of this.listadoProductos) {
          if (Array.isArray(listadoProductosOrdenados[producto.familia])) {
            if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria])) {
              if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria])) {
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
              } else {
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
              }
            } else {
              listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
              listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
              listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
            }
          } else {
            listadoProductosOrdenados[producto.familia] = new Array();
            listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
            listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
            listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
          }
        }

        const print: any[] = [];
        if (this.listadoProductos[0].precio) {
          print.push(
            [
              'Foto',
              'Código',
              'Descripción',
              'Código de barras',
              'Unidad de medida',
              'Precio'+(this.iva_usuario.includes('NO INCLUYE') ? ' + I.V.A.' : (this.iva_usuario.includes('INCLUYE') ? ' I.V.A. Incluido' : (this.iva_usuario.includes('FINAL') ? ' Final' : ''))),
            ]
          );
        } else {
          print.push(
            [
              'Foto',
              'Código',
              'Descripción',
              'Código de barras',
              'Unidad de medida',
              'Precio 1',
              'Precio 2',
            ]
          );
        }
        print.push([]);

        let i = 3;
        let merges: any[] = [];
        let arrayProductImages: any[] = [];
        let productImgsPromises: Promise<unknown>[] = [];
        let productBlobsPromises: Promise<unknown>[] = [];
        let productImagesPromises: Promise<unknown>[] = [];
        let productImgs: any[] = [];
        let productBlobs: any[] = [];
        let productImages: any[] = [];

        for (const key in listadoProductosOrdenados) {
          if (Object.prototype.hasOwnProperty.call(listadoProductosOrdenados, key)) {
            merges.push({ row: i, cant: 3 });
            i = i + 3;
            print.push([key]);
            print.push([]);
            print.push([]);
            const familias = listadoProductosOrdenados[key];
            for (const key in familias) {
              if (Object.prototype.hasOwnProperty.call(familias, key)) {
                merges.push({ row: i, cant: 2 });
                i = i + 2;
                print.push([key]);
                print.push([]);
                const categorias = familias[key];
                for (const key in categorias) {
                  if (Object.prototype.hasOwnProperty.call(categorias, key)) {
                    merges.push({ row: i, cant: 1 });
                    i = i + 1;
                    print.push([key]);
                    const subcategorias = categorias[key];
                    for (const key in subcategorias) {
                      if (Object.prototype.hasOwnProperty.call(subcategorias, key)) {
                        i = i + 1;
                        const producto = subcategorias[key];

                        if (this.listaPreciosImagen) {
                          (function (index) {
                            arrayProductImages.push({ i: index, producto, imagen: producto.imagen.replace('.webp', '.jpg') });
                          })(i-1.75); // (i-2.75-minF-minC-minS)
                        }

                        if (producto.precio) {
                          print.push(
                            [
                              null,
                              producto.codigo_interno,
                              producto.nombre + ' - ' + producto.nombre_adicional,
                              producto.codigo_barras,
                              producto.unidad_medida,
                              producto.precio,
                            ],
                          );
                        } else {
                          print.push(
                            [
                              null,
                              producto.codigo_interno,
                              producto.nombre + ' - ' + producto.nombre_adicional,
                              producto.codigo_barras,
                              producto.unidad_medida,
                              producto.precio_1,
                              producto.precio_2,
                            ],
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        const hoy = new Date();
        const hoyString = ('0' + hoy.getDate()).slice(-2) + '-' + ('0' + (hoy.getMonth() + 1)).slice(-2) + '-' + hoy.getFullYear();

        const Excel = require('exceljs');
        const FileSaver = require('file-saver');

        // Workbook and properties

        const wb = new Excel.Workbook();
        wb.creator = 'SINA';
        wb.lastModifiedBy = 'SINA';
        wb.created = hoy;
        wb.modified = hoy;
        wb.lastPrinted = hoy;
        wb.properties.date1904 = true;
        wb.views = [
          {
            x: 0, y: 0, width: 10000, height: 20000,
            firstSheet: 0, activeTab: 1, visibility: 'visible'
          }
        ]

        // Worksheet and properties

        const ws = wb.addWorksheet(environment.APP_DOMAIN+'_Lista-de-precios',
          {
            properties: { tabColor: { argb:'FF057AFF' } },
            pageSetup: { paperSize: 9, orientation: 'portrait', fitToPage: true, fitToWidth: 1, fitToHeight: 0, printTitlesRow: '1:2' },
            headerFooter: { oddHeader: 'Lista de precios '+environment.APP_DOMAIN+' al ' + hoyString + ' - ' + this.iva_usuario, oddFooter: "&LPágina &P de &N" }
          }
        );
        ws.state = 'visible';
        ws.properties.defaultRowHeight = 50;
        ws.autoFilter = 'B:C';

        // Adding Rows

        ws.addRows(print);

        // Styles

        // Iterate over all rows that have values in a worksheet to set custom height
        ws.eachRow(function(row: any, rowNumber: number) {
          row.height = 50;
        });

        // Columna precio alineada a la derecha

        ws.getColumn('A').alignment = { vertical: 'middle', horizontal: 'center' };
        ws.getColumn('F').alignment = { vertical: 'middle', horizontal: 'right' };
        if (this.listadoProductos[0]?.precio_2) {
          ws.getColumn('G').alignment = { vertical: 'middle', horizontal: 'right' };
        }

        // Anchos de columna

        ws.getColumn('A').width = 12;
        ws.getColumn('B').width = 12;
        ws.getColumn('C').width = 47;
        ws.getColumn('D').width = 15;
        ws.getColumn('E').width = 15;
        ws.getColumn('F').width = 14;
        if (this.listadoProductos[0]?.precio_2) {
          ws.getColumn('G').width = 14;
        }

        // Borde izquierdo primer columna
        // Borde derecho a cada columna

        ws.getColumn('A').border = { left: { style: 'thin' }, right: { style: 'thin' } };
        ws.getColumn('B').border = { right: { style: 'thin' } };
        ws.getColumn('C').border = { right: { style: 'thin' } };
        ws.getColumn('D').border = { right: { style: 'thin' } };
        ws.getColumn('E').border = { right: { style: 'thin' } };
        ws.getColumn('F').border = { right: { style: 'thin' } };
        if (this.listadoProductos[0]?.precio_2) {
          ws.getColumn('F').border = { right: { style: 'mediumDashed' } };
          ws.getColumn('G').border = { right: { style: 'thin'} };
        }

        // Borde inferior a la ultima fila
        ws.getCell('A' + ws.rowCount).border = { left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
        ws.getCell('B' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        ws.getCell('C' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        ws.getCell('D' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        ws.getCell('E' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        ws.getCell('F' + ws.rowCount).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        if (this.listadoProductos[0]?.precio_2) {
          ws.getCell('F' + ws.rowCount).border = { right: { style: 'mediumDashed', bottom: { style: 'thin' } } };
          ws.getCell('G' + ws.rowCount).border = { right: { style: 'thin'}, bottom: { style: 'thin' } };
        }

        // Cabeceras centradas

        const alignMiddleCenter = { vertical: 'middle', horizontal: 'center', wrapText: true };
        ws.getCell('A1').alignment = alignMiddleCenter;
        ws.getCell('B1').alignment = alignMiddleCenter;
        ws.getCell('C1').alignment = alignMiddleCenter;
        ws.getCell('D1').alignment = alignMiddleCenter;
        ws.getCell('E1').alignment = alignMiddleCenter;
        ws.getCell('F1').alignment = alignMiddleCenter;
        if (this.listadoProductos[0]?.precio_2) {
          ws.getCell('G1').alignment = alignMiddleCenter;
        }

        // Cabeceras Arial 12 y negrita

        const fontArial12Bold = { name: 'Arial', size: 12, bold: true };
        ws.getCell('A1').font = fontArial12Bold;
        ws.getCell('B1').font = fontArial12Bold;
        ws.getCell('C1').font = fontArial12Bold;
        ws.getCell('D1').font = fontArial12Bold;
        ws.getCell('E1').font = fontArial12Bold;
        ws.getCell('F1').font = fontArial12Bold;
        if (this.listadoProductos[0]?.precio_2) {
          ws.getCell('G1').font = fontArial12Bold;
        }

        // Cabeceras color de fondo gris

        const fillSolidGris = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFEAEAEA' } };
        ws.getCell('A1').fill = fillSolidGris;
        ws.getCell('B1').fill = fillSolidGris;
        ws.getCell('C1').fill = fillSolidGris;
        ws.getCell('D1').fill = fillSolidGris;
        ws.getCell('E1').fill = fillSolidGris;
        ws.getCell('F1').fill = fillSolidGris;
        if (this.listadoProductos[0]?.precio_2) {
          ws.getCell('G1').fill = fillSolidGris;
        }

        // Cabeceras con borde

        const bordeSimple = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        ws.getCell('A1').border = bordeSimple;
        ws.getCell('B1').border = bordeSimple;
        ws.getCell('C1').border = bordeSimple;
        ws.getCell('D1').border = bordeSimple;
        ws.getCell('E1').border = bordeSimple;
        ws.getCell('F1').border = bordeSimple;
        if (this.listadoProductos[0]?.precio_2) {
          ws.getCell('G1').border = bordeSimple;
        }

        // Mergeo cada cabecera con su row de abajo

        ws.mergeCells('A1', 'A2');
        ws.mergeCells('B1', 'B2');
        ws.mergeCells('C1', 'C2');
        ws.mergeCells('D1', 'D2');
        ws.mergeCells('E1', 'E2');
        ws.mergeCells('F1', 'F2');
        if (this.listadoProductos[0]?.precio_2) {
          ws.mergeCells('G1', 'G2');
        }

        // Merge de las rows que obtuve antes
        const fillSolidAzul = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF00B0F0' } };

        for (const m of merges) {
          let idCeldaInicio = 'A' + m.row;
          let idCeldaFin = 'F' + (m.row + (m.cant - 1));
          if (this.listadoProductos[0]?.precio_2) {
            idCeldaFin = 'G' + (m.row + (m.cant - 1));
          }
          ws.getCell(idCeldaInicio).font = fontArial12Bold;
          ws.getCell(idCeldaInicio).border = bordeSimple;
          ws.getCell(idCeldaInicio).alignment = alignMiddleCenter;
          if (m.cant === 3) {
            ws.getCell(idCeldaInicio).fill = fillSolidAzul;
          }
          ws.mergeCells(idCeldaInicio, idCeldaFin);
        }

        try {
          let c = 0;
          const totalImgs = arrayProductImages.length;
          const totalImgsChunks = totalImgs / 25;
          while (arrayProductImages.length > 0) {
            productImgsPromises = [];
            const arrayImg: any[] = arrayProductImages.splice(0, 25);
            for (const arrayData of arrayImg) {
              productImgsPromises.push(new Promise((resolve, reject) => {
                fetch(arrayData.producto.imagen.replace('.webp', '.jpg'))
                .then((img: any) => {
                  resolve({ i: arrayData.i, producto: arrayData.producto, img });
                })
                .catch((error: any) => {
                  resolve({ i: arrayData.i, producto: arrayData.producto });
                })
              }));
            }

            const productImgsResolved = await Promise.all(productImgsPromises);
            productImgs.push(...productImgsResolved);
            c++;
            this.fileDownloadProgressValue = Math.min(Math.max(((c * 100) / totalImgsChunks), 0), 100).toFixed(0); // Clamp entre 0 y 100
          }

          for (const imgData of productImgs) {
            if (imgData.img) productBlobsPromises.push(new Promise((resolve, reject) => {
              imgData.img.blob()
              .then((blob: any) => {
                resolve({ i: imgData.i, producto: imgData.producto, blob });
              })
              .catch((error: any) => {
                resolve({ i: imgData.i, producto: imgData.producto });
              })
            }));
          }

          productBlobs = await Promise.all(productBlobsPromises);
          for (const blobData of productBlobs) {
            if (blobData.i && blobData.producto && blobData.blob) {
              productImagesPromises.push(new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve({ producto: blobData.producto.codigo_interno, base64: reader.result, col: 0.31, row: blobData.i });
                };
                reader.onerror = () => {
                  reject(this);
                };
                reader.readAsDataURL(blobData.blob);
              }));
            }
          }

          productImages = await Promise.all(productImagesPromises);
          for (const pi of productImages) {
            // Add image to workbook by base64
            const imgID = wb.addImage({
              base64: pi.base64,
              extension: 'jpg',
            });

            ws.addImage(imgID, {
              tl: { col: pi.col, row: pi.row },
              ext: { width: 48, height: 32 }
            });
          }
        } catch (error) {
          this.data.log('Error adding products images at excel download, exception:', error);
        }

        // Descarga del archivo

        await wb.xlsx.writeBuffer().then((data: any) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
          FileSaver.saveAs(blob, 'Lista de precios '+environment.APP_DOMAIN+' al ' + hoyString + '.xlsx');
        });
      }

      for (const key in document.querySelectorAll('#loaderFile')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
          const element = document.querySelectorAll('#loaderFile')[key];
          (element as HTMLElement).style.display = 'none';
        }
      }
      for (const key in document.querySelectorAll('#loaderFileMsg')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFileMsg'), key)) {
          const element = document.querySelectorAll('#loaderFileMsg')[key];
          (element as HTMLElement).style.display = 'none';
        }
      }
    })
    .catch(($error) => {
      this.data.log('descargarlistaxlsx error cuenta:', $error);
    });
  }

  public showPDFModal: boolean = false;
  public precioUnico: boolean = true;

  descargarListaPDF() {
    this.showPDFModal = true;
    this.fileDownloadProgressValue = '0';

    for (const key in document.querySelectorAll('#loaderFile')) {
      if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
        const element = document.querySelectorAll('#loaderFile')[key];
        (element as HTMLElement).style.display = 'flex';
        (element as HTMLElement).style.flexDirection = 'column';
      }
    }
    this.auth.get('producto/listaPrecios')
    .then(async ($response: any)  => {
      if ($response.response) {
        const hoy = new Date();
        const hoyString = ('0' + hoy.getDate()).slice(-2) + '-' + ('0' + (hoy.getMonth() + 1)).slice(-2) + '-' + hoy.getFullYear();

        const productos = $response.response;
        this.listadoProductos = $response.response;
        this.precioUnico = this.listadoProductos[0]?.precio_2 ? false : true;
        let listadoProductosOrdenados: any[] = [];

        let productImgsPromises: Promise<any>[] = [];
        let productBlobsPromises: Promise<any>[] = [];
        let productImagesPromises: Promise<any>[] = [];
        let productImgsResolved: any[] = [];
        let productBlobs: any[] = [];
        let productImages: any[] = [];

        try {
          let c = 0;
          const totalImgs = productos.length;
          const totalImgsChunks = totalImgs / 25;
          while (productos.length > 0) {
            productImgsPromises = [];
            productBlobsPromises = [];
            productImagesPromises = [];
            productImgsResolved = [];
            productBlobs = [];
            productImages = [];

            const arrayProducts: any[] = productos.splice(0, 25);
            if(this.listaPreciosImagen) {
              for (const p of arrayProducts) {
                productImgsPromises.push(new Promise((resolve, reject) => {
                  fetch(p.imagen.replace('.webp', '.jpg'))
                  .then((img: any) => {
                    resolve({ ...p, img });
                  })
                  .catch((error: any) => {
                    resolve(p);
                  })
                }));
              }

              productImgsResolved = await Promise.all(productImgsPromises);
              for (const p of productImgsResolved) {
                if (p.img) {
                  productBlobsPromises.push(new Promise((resolve, reject) => {
                    p.img.blob()
                    .then((blob: any) => {
                      resolve({ ...p, blob });
                    })
                    .catch((error: any) => {
                      resolve(p);
                    })
                  }));
                }
                else {
                  productBlobsPromises.push(new Promise((resolve, reject) => { resolve(p); }));
                }
              }

              productBlobs = await Promise.all(productBlobsPromises);
              for (const p of productBlobs) {
                if (p && p.blob) {
                  productImagesPromises.push(new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      resolve({ ...p, base64: reader.result });
                    };
                    reader.onerror = () => {
                      resolve(p);
                    };
                    reader.readAsDataURL(p.blob);
                  }));
                }
                else {
                  productImagesPromises.push(new Promise((resolve, reject) => { resolve(p); }));
                }
              }
            }

            productImages = this.listaPreciosImagen ? await Promise.all(productImagesPromises) : arrayProducts;
            for (const producto of productImages) {
              if (Array.isArray(listadoProductosOrdenados[producto.familia])) {
                if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria])) {
                  if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria])) {
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                  } else {
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                  }
                } else {
                  listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                }
              } else {
                listadoProductosOrdenados[producto.familia] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
              }
            }
            c++;
            this.fileDownloadProgressValue = Math.min(Math.max((((c * 100) / totalImgsChunks) / 2), 0), 50).toFixed(0); // Clamp entre 0 y 50
          }
        } catch (error) {
          this.data.log('Error adding products at PDF download, exception:', error);
        }

        productImgsPromises = [];
        productBlobsPromises = [];
        productImagesPromises = [];
        productImgsResolved = [];
        productBlobs = [];
        productImages = [];

        const pdfModalMain = document.getElementById('pdf-modal-main');

        if (pdfModalMain) {
          let currFamily = '';
          for (const key in listadoProductosOrdenados) {
            if (Object.prototype.hasOwnProperty.call(listadoProductosOrdenados, key)) {
              currFamily = key;
              pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                <p class="fs-2">${key}</p>
              </div>`);

              const familias = listadoProductosOrdenados[key];
              for (const key in familias) {
                if (Object.prototype.hasOwnProperty.call(familias, key)) {
                  pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                    <p class="fs-4">${key}</p>
                  </div>`);

                  const categorias = familias[key];
                  for (const key in categorias) {
                    if (Object.prototype.hasOwnProperty.call(categorias, key)) {
                      pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                        <p class="fs-6">${key}</p>
                      </div>`);

                      const subcategorias = categorias[key];
                      let rows = "";
                      for (const p of subcategorias) {
                        rows += `<tr>
                          <td>` + (this.listaPreciosImagen ? `<div class="flex justify-content-center align-items-center"><img src="${p.base64}" title="F" alt="F" width="24" height="16"></div>` : '') + `</td>
                          <td>${p.codigo_interno}</td>
                          <td>${p.nombre}</td>
                          <td>${p.codigo_barras}</td>
                          <td>${p.unidad_medida}</td>
                          <td>${this.precioUnico ? p.precio : p.precio_1}</td>
                          ${!this.precioUnico ? ('<td>' + p.precio_2 + '</td>') : ''}
                        </tr>`;
                      }
                      pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                        <table>
                          <thead>
                            <tr>
                              <th>Foto</th>
                              <th>Código</th>
                              <th>Descripción</th>
                              <th>Código de barras</th>
                              <th>Unidad de medida</th>
                              <th>${this.precioUnico ? ('Precio'+(this.iva_usuario.includes('NO INCLUYE') ? ' + I.V.A.' : (this.iva_usuario.includes('INCLUYE') ? ' I.V.A. Incluido' : (this.iva_usuario.includes('FINAL') ? ' Final' : '')))) : 'Precio 1'}</th>
                              ${!this.precioUnico ? '<th>Precio 2</th>' : ''}
                            </tr>
                          </thead>
                          <tbody>
                            ${rows}
                          </tbody>
                        </table>
                      </div>`);
                    }
                  }
                }
              }
            }
          }

          const doc = new jsPDF();
          const children = Array.from(pdfModalMain.children);
          let count = 1;
          for (const div of children) {
            count++;
            const canvas: HTMLCanvasElement = await html2canvas(div as HTMLElement, { useCORS: true, allowTaint: false, logging: true });
            document.body.insertAdjacentHTML('beforeend', '<div id="canvas-placeholder' + div.id + '" style="visibility: hidden"></div>');
            document.getElementById('canvas-placeholder' + div.id)?.append(canvas);
            const totalWidth = canvas.clientWidth * window.devicePixelRatio;
            const totalHeigth = canvas.clientHeight * window.devicePixelRatio;
            document.getElementById('canvas-placeholder' + div.id)?.remove();
            const pageHeight = totalWidth * (297/206);
            const ctx = canvas.getContext('2d');
            if (ctx) {
              let i = 0
              let currY = 0
              do {
                i++;
                const miniCanvasData = ctx.getImageData(0, 1 + currY, totalWidth, pageHeight);
                currY += pageHeight;
                const newMiniCanvas = document.createElement('canvas');
                newMiniCanvas.width = totalWidth;
                newMiniCanvas.height = pageHeight;
                const newCtx = newMiniCanvas.getContext('2d');
                if (newCtx && miniCanvasData) {
                  newCtx.putImageData(miniCanvasData, 0, 0);
                  const img = newMiniCanvas.toDataURL("image/jpeg", 0.8);
                  // document.body.append(newMiniCanvas)

                  try {
                    doc.addImage(img, 'JPEG', 2, 0, 206, 297);
                    doc.addPage();
                  } catch (error) {
                    this.data.log('descargarlistapdf error pdf:', error);
                  }
                }
              } while ((pageHeight * i) <= totalHeigth)
            }
            this.fileDownloadProgressValue = Math.min(Math.max(((100 * ((count-1) / children.length) / 2) + 50), 0), 100).toFixed(0); // Clamp entre 0 y 100
          }

          doc.deletePage(doc.internal.pages.length-1);
          doc.save('Lista de precios '+environment.APP_DOMAIN+' al ' + hoyString + '.pdf');
        }
      }

      for (const key in document.querySelectorAll('#loaderFile')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
          const element = document.querySelectorAll('#loaderFile')[key];
          (element as HTMLElement).style.display = 'none';
        }
      }
      for (const key in document.querySelectorAll('#loaderFileMsg')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFileMsg'), key)) {
          const element = document.querySelectorAll('#loaderFileMsg')[key];
          (element as HTMLElement).style.display = 'none';
        }
      }

      this.showPDFModal = false;
    })
    .catch(($error) => {
      this.data.log('descargarlistapdf error home:', $error);
      this.showPDFModal = false;
    });
  }

  async descargarListaFrecuentesXLSX() {
    this.fileDownloadProgressValue = '0';

    for (const key in document.querySelectorAll('#loaderFile')) {
      if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
        const element = document.querySelectorAll('#loaderFile')[key];
        (element as HTMLElement).style.display = 'flex';
        (element as HTMLElement).style.flexDirection = 'column';
      }
    }
    this.auth.get('producto/listaFrecuentes')
    .then(async ($response)  => {
      if ($response.response) {
        this.listadoFrecuentes = $response.response;

        const arrowUp = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADLSURBVDhPlY67DcJAEETXRZBQhwMSAoQxNVALETkd0ABdAOKTUwW0AATm2Z6VDD4fZqRn+XZmb85iKsxmMNbxf7F8hp2O/0nthZho3F8sle1+wUHjfmKh2e5MZf8W4Wa7c5QdF8FQu5Mp1i1CoXbnrFhYBGLtTq54W5ixdif8Cow+7U77FQxPX6EYF63VYtDVvoeui+da5/QZesAGUtmln2r2BM/Vr+An0+AOSxhWRkB4A1jBDcqdPOGzxrvCNjF7VckfYoeoLcxs9AZpdd/dZrRAVwAAAABJRU5ErkJggg==';
        const arrowDown = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAC8SURBVDhPnY7LDcIwEEQnHSBRBCVwyAEQdMYdDjRAB/xuNIFEBzQAJSxjvGvhYDsObzSJnN14poFgA+COBnu+6xCM+JzSrTu0tNOT3tLjz1IK96NgTT9op7kNbvrBtKMnfkjcor/8RZsuOiWCWfgc60p3LzdpupFfTOkr3RAswrhfnXSjrsVZtxMIlmEtr0y6UW5x0q0CglVY/1VPupFucdRpBekWlelG3GJAuhG3GJhu+BYHPf2BSy6mA28evt5OXyFPfgAAAABJRU5ErkJggg==';

        const print: any[] = [];
        if (this.listadoFrecuentes[0].precio) {
          print.push(
            [
              'Foto',
              'Código',
              'Descripción',
              'Código de barras',
              'Unidad de medida',
              'Precio'+(this.iva_usuario.includes('NO INCLUYE') ? ' + I.V.A.' : (this.iva_usuario.includes('INCLUYE') ? ' I.V.A. Incluido' : (this.iva_usuario.includes('FINAL') ? ' Final' : ''))),
              'CPUC',
            ]
          );
        } else {
          print.push(
            [
              'Foto',
              'Código',
              'Descripción',
              'Código de barras',
              'Unidad de medida',
              'Precio 1',
              'Precio 2',
              'CPUC',
            ]
          );
        }
        print.push([]);

        let i = 2.25;
        let images: any[] = [];
        let arrayProductImages: any[] = [];
        let productImgsPromises: Promise<unknown>[] = [];
        let productBlobsPromises: Promise<unknown>[] = [];
        let productImagesPromises: Promise<unknown>[] = [];
        let productImgs: any[] = [];
        let productBlobs: any[] = [];
        let productImages: any[] = [];

        this.listadoFrecuentes?.forEach((producto) => {

          if(this.listaPreciosImagen) {
            (function (index) {
              arrayProductImages.push({ i: index, producto, imagen: producto.imagen.replace('.webp', '.jpg') });
            })(i);
          }

          if (producto.precio) {
            print.push(
              [
                null,
                producto.codigo_interno,
                producto.nombre + ' - ' + producto.nombre_adicional,
                producto.codigo_barras,
                producto.unidad_medida,
                producto.precio,
                producto.cpuc === '-' ? '-' : 'NC',
              ],
            );
            if (producto.cpuc === "UP") {
              images.push({ col: 6.31, row: i, arrow: "UP"});
            } else if (producto.cpuc === "DOWN") {
              images.push({ col: 6.31, row: i, arrow: "DOWN"});
            }
          } else {
            print.push(
              [
                null,
                producto.codigo_interno,
                producto.nombre + ' - ' + producto.nombre_adicional,
                producto.codigo_barras,
                producto.unidad_medida,
                producto.precio_1,
                producto.precio_2,
                producto.cpuc === '-' ? '-' : 'NC',
              ],
            );
            if (producto.cpuc === "UP") {
              images.push({ col: 7.31, row: i, arrow: "UP"});
            } else if (producto.cpuc === "DOWN") {
              images.push({ col: 7.31, row: i, arrow: "DOWN"});
            }
          }
          i++;
        });

        print.push(['* CPUC: Comparación de precios de últimas compras. ( 15 días )']);
        print.push(['* NC: No se realizó la comparación de precios porque la compra se realizó hace más de 15 días.']);

        const hoy = new Date();
        const hoyString = ('0' + hoy.getDate()).slice(-2) + '-' + ('0' + (hoy.getMonth() + 1)).slice(-2) + '-' + hoy.getFullYear();

        const Excel = require('exceljs');
        const FileSaver = require('file-saver');

        // Workbook and properties

        const wb = new Excel.Workbook();
        wb.creator = 'SINA';
        wb.lastModifiedBy = 'SINA';
        wb.created = hoy;
        wb.modified = hoy;
        wb.lastPrinted = hoy;
        wb.properties.date1904 = true;
        wb.views = [
          {
            x: 0, y: 0, width: 10000, height: 20000,
            firstSheet: 0, activeTab: 1, visibility: 'visible'
          }
        ]

        // Worksheet and properties

        const ws = wb.addWorksheet(environment.APP_DOMAIN+'_Lista-frecuentes',
          {
            properties: { tabColor: { argb:'FF057AFF' } },
            pageSetup: { paperSize: 9, orientation: 'portrait', fitToPage: true, fitToWidth: 1, fitToHeight: 0, printTitlesRow: '1:2' },
            headerFooter: { oddHeader: 'Lista de frecuentes '+environment.APP_DOMAIN+' al ' + hoyString + ' - ' + this.iva_usuario, oddFooter: "&LPágina &P de &N" }
          }
        );
        ws.state = 'visible';
        ws.properties.defaultRowHeight = 50;
        ws.autoFilter = 'B:C';

        // Adding Rows

        ws.addRows(print);

        // Styles

        // Iterate over all rows that have values in a worksheet to set custom height
        ws.eachRow(function(row: any, rowNumber: number) {
          row.height = 50;
        });

        // Columnas precio alineada a la derecha y CPUC al centro

        ws.getColumn('A').alignment = { vertical: 'middle', horizontal: 'center' };
        ws.getColumn('F').alignment = { vertical: 'middle', horizontal: 'right' };
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.getColumn('G').alignment = { vertical: 'middle', horizontal: 'right' };
          ws.getColumn('H').alignment = { vertical: 'middle', horizontal: 'center' };
        } else {
          ws.getColumn('G').alignment = { vertical: 'middle', horizontal: 'center' };
        }

        // Anchos de columna

        ws.getColumn('A').width = 12;
        ws.getColumn('B').width = 12;
        ws.getColumn('C').width = 47;
        ws.getColumn('D').width = 15;
        ws.getColumn('E').width = 15;
        ws.getColumn('F').width = 14;
        ws.getColumn('G').width = 8;
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.getColumn('G').width = 14;
          ws.getColumn('H').width = 8;
        }

        // Border izquierdo primer columna
        // Borde derecho a cada columna

        ws.getColumn('A').border = { left: { style: 'thin' }, right: { style: 'thin' } };
        ws.getColumn('B').border = { right: { style: 'thin' } };
        ws.getColumn('C').border = { right: { style: 'thin' } };
        ws.getColumn('D').border = { right: { style: 'thin' } };
        ws.getColumn('E').border = { right: { style: 'thin' } };
        ws.getColumn('F').border = { right: { style: 'thin' } };
        ws.getColumn('G').border = { right: { style: 'thin' } };
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.getColumn('F').border = { right: { style: 'mediumDashed' } };
          ws.getColumn('H').border = { right: { style: 'thin' } };
        }

        // Borde inferior y merge a la ultima y ante ultima fila
        ws.getCell('A' + (ws.rowCount - 1)).border = { left: { style: 'thin' }, right: { style: 'thin' }, top: { style: 'thin' }, bottom: { style: 'thin' } };
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.mergeCells('A' + (ws.rowCount - 1), 'H' + (ws.rowCount - 1));
        } else {
          ws.mergeCells('A' + (ws.rowCount - 1), 'G' + (ws.rowCount - 1));
        }
        ws.getCell('A' + ws.rowCount).border = { left: { style: 'thin' }, right: { style: 'thin' }, top: { style: 'thin' }, bottom: { style: 'thin' } };
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.mergeCells('A' + ws.rowCount, 'H' + ws.rowCount);
        } else {
          ws.mergeCells('A' + ws.rowCount, 'G' + ws.rowCount);
        }

        // Cabeceras centradas

        const alignMiddleCenter = { vertical: 'middle', horizontal: 'center', wrapText: true };
        ws.getCell('A1').alignment = alignMiddleCenter;
        ws.getCell('B1').alignment = alignMiddleCenter;
        ws.getCell('C1').alignment = alignMiddleCenter;
        ws.getCell('D1').alignment = alignMiddleCenter;
        ws.getCell('E1').alignment = alignMiddleCenter;
        ws.getCell('F1').alignment = alignMiddleCenter;
        ws.getCell('G1').alignment = alignMiddleCenter;
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.getCell('H1').alignment = alignMiddleCenter;
        }

        // Cabeceras Arial 12 y negrita

        const fontArial12Bold = { name: 'Arial', size: 12, bold: true };
        ws.getCell('A1').font = fontArial12Bold;
        ws.getCell('B1').font = fontArial12Bold;
        ws.getCell('C1').font = fontArial12Bold;
        ws.getCell('D1').font = fontArial12Bold;
        ws.getCell('E1').font = fontArial12Bold;
        ws.getCell('F1').font = fontArial12Bold;
        ws.getCell('G1').font = fontArial12Bold;
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.getCell('H1').font = fontArial12Bold;
        }

        // Cabeceras color de fondo gris

        const fillSolidGris = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFEAEAEA' } };
        ws.getCell('A1').fill = fillSolidGris;
        ws.getCell('B1').fill = fillSolidGris;
        ws.getCell('C1').fill = fillSolidGris;
        ws.getCell('D1').fill = fillSolidGris;
        ws.getCell('E1').fill = fillSolidGris;
        ws.getCell('F1').fill = fillSolidGris;
        ws.getCell('G1').fill = fillSolidGris;
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.getCell('H1').fill = fillSolidGris;
        }

        // Cabeceras con borde

        const bordeSimple = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        ws.getCell('A1').border = bordeSimple;
        ws.getCell('B1').border = bordeSimple;
        ws.getCell('C1').border = bordeSimple;
        ws.getCell('D1').border = bordeSimple;
        ws.getCell('E1').border = bordeSimple;
        ws.getCell('F1').border = bordeSimple;
        ws.getCell('G1').border = bordeSimple;
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.getCell('H1').border = bordeSimple;
        }

        // Mergeo cada cabecera con su row de abajo

        ws.mergeCells('A1', 'A2');
        ws.mergeCells('B1', 'B2');
        ws.mergeCells('C1', 'C2');
        ws.mergeCells('D1', 'D2');
        ws.mergeCells('E1', 'E2');
        ws.mergeCells('F1', 'F2');
        ws.mergeCells('G1', 'G2');
        if (this.listadoFrecuentes[0]?.precio_2) {
          ws.mergeCells('H1', 'H2');
        }

        try {
          let c = 0;
          const totalImgs = arrayProductImages.length;
          const totalImgsChunks = totalImgs / 25;
          while (arrayProductImages.length > 0) {
            productImgsPromises = [];
            const arrayImg: any[] = arrayProductImages.splice(0, 25);
            for (const arrayData of arrayImg) {
              productImgsPromises.push(new Promise((resolve, reject) => {
                fetch(arrayData.producto.imagen.replace('.webp', '.jpg'))
                .then((img: any) => {
                  resolve({ i: arrayData.i, producto: arrayData.producto, img });
                })
                .catch((error: any) => {
                  resolve({ i: arrayData.i, producto: arrayData.producto });
                })
              }));
            }

            const productImgsResolved = await Promise.all(productImgsPromises);
            productImgs.push(...productImgsResolved);
            c++;
            this.fileDownloadProgressValue = Math.min(Math.max(((c * 100) / totalImgsChunks), 0), 100).toFixed(0); // Clamp entre 0 y 100
          }

          for (const imgData of productImgs) {
            if (imgData.img) productBlobsPromises.push(new Promise((resolve, reject) => {
              imgData.img.blob()
              .then((blob: any) => {
                resolve({ i: imgData.i, producto: imgData.producto, blob });
              })
              .catch((error: any) => {
                resolve({ i: imgData.i, producto: imgData.producto });
              })
            }));
          }

          productBlobs = await Promise.all(productBlobsPromises);
          for (const blobData of productBlobs) {
            if (blobData.i && blobData.producto && blobData.blob) {
              productImagesPromises.push(new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve({ producto: blobData.producto.codigo_interno, base64: reader.result, col: 0.31, row: blobData.i });
                };
                reader.onerror = () => {
                  reject(this);
                };
                reader.readAsDataURL(blobData.blob);
              }));
            }
          }

          productImages = await Promise.all(productImagesPromises);
          for (const pi of productImages) {
            // Add image to workbook by base64
            const imgID = wb.addImage({
              base64: pi.base64,
              extension: 'jpg',
            });

            ws.addImage(imgID, {
              tl: { col: pi.col, row: pi.row },
              ext: { width: 48, height: 32 }
            });
          }
        } catch (error) {
          this.data.log('Error adding products images at excel download, exception:', error);
        }

        for (let i = 0; i < images.length; i++) {
          // Add image to workbook by base64
          const arrowUpID = wb.addImage({
            base64: arrowUp,
            extension: 'png',
          });
          const arrowDownID = wb.addImage({
            base64: arrowDown,
            extension: 'png',
          });

          const m = images[i];

          if (m.arrow === "UP") {
            ws.addImage(arrowUpID, {
              tl: { col: m.col, row: m.row },
              ext: { width: 16, height: 16 }
            });
          } else if (m.arrow === "DOWN") {
            ws.addImage(arrowDownID, {
              tl: { col: m.col, row: m.row },
              ext: { width: 16, height: 16 }
            });
          }
        }

        // Descarga del archivo

        await wb.xlsx.writeBuffer().then((data: any) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
          FileSaver.saveAs(blob, 'Lista de frecuentes '+environment.APP_DOMAIN+' al ' + hoyString + '.xlsx');
        });
      }

      for (const key in document.querySelectorAll('#loaderFile')) {
        if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
          const element = document.querySelectorAll('#loaderFile')[key];
          (element as HTMLElement).style.display = 'none';
        }
      }
    })
    .catch(($error) => {
      this.data.log('productosmaspedidos frecuentes error cuenta:', $error);
    });
  }

  descargarListaFrecuentesPDF() {
    this.showPDFModal = true;
    this.fileDownloadProgressValue = '0';

    for (const key in document.querySelectorAll('#loaderFile')) {
      if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
        const element = document.querySelectorAll('#loaderFile')[key];
        (element as HTMLElement).style.display = 'flex';
        (element as HTMLElement).style.flexDirection = 'column';
      }
    }

    this.auth.get('producto/listaFrecuentes')
    .then(async ($response)  => {
      if ($response.response) {
        const hoy = new Date();
        const hoyString = ('0' + hoy.getDate()).slice(-2) + '-' + ('0' + (hoy.getMonth() + 1)).slice(-2) + '-' + hoy.getFullYear();

        const arrowUp = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADLSURBVDhPlY67DcJAEETXRZBQhwMSAoQxNVALETkd0ABdAOKTUwW0AATm2Z6VDD4fZqRn+XZmb85iKsxmMNbxf7F8hp2O/0nthZho3F8sle1+wUHjfmKh2e5MZf8W4Wa7c5QdF8FQu5Mp1i1CoXbnrFhYBGLtTq54W5ixdif8Cow+7U77FQxPX6EYF63VYtDVvoeui+da5/QZesAGUtmln2r2BM/Vr+An0+AOSxhWRkB4A1jBDcqdPOGzxrvCNjF7VckfYoeoLcxs9AZpdd/dZrRAVwAAAABJRU5ErkJggg==';
        const arrowDown = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAC8SURBVDhPnY7LDcIwEEQnHSBRBCVwyAEQdMYdDjRAB/xuNIFEBzQAJSxjvGvhYDsObzSJnN14poFgA+COBnu+6xCM+JzSrTu0tNOT3tLjz1IK96NgTT9op7kNbvrBtKMnfkjcor/8RZsuOiWCWfgc60p3LzdpupFfTOkr3RAswrhfnXSjrsVZtxMIlmEtr0y6UW5x0q0CglVY/1VPupFucdRpBekWlelG3GJAuhG3GJhu+BYHPf2BSy6mA28evt5OXyFPfgAAAABJRU5ErkJggg==';

        const productos = $response.response;
        this.listadoFrecuentes = $response.response;
        this.precioUnico = this.listadoFrecuentes[0]?.precio_2 ? false : true;
        let listadoProductosOrdenados: any[] = [];

        let productImgsPromises: Promise<any>[] = [];
        let productBlobsPromises: Promise<any>[] = [];
        let productImagesPromises: Promise<any>[] = [];
        let productImgsResolved: any[] = [];
        let productBlobs: any[] = [];
        let productImages: any[] = [];

        try {
          let c = 0;
          const totalImgs = productos.length;
          const totalImgsChunks = totalImgs / 25;
          while (productos.length > 0) {
            productImgsPromises = [];
            productBlobsPromises = [];
            productImagesPromises = [];
            productImgsResolved = [];
            productBlobs = [];
            productImages = [];

            const arrayProducts: any[] = productos.splice(0, 25);
            if(this.listaPreciosImagen) {
              for (const p of arrayProducts) {
                productImgsPromises.push(new Promise((resolve, reject) => {
                  fetch(p.imagen.replace('.webp', '.jpg'))
                  .then((img: any) => {
                    resolve({ ...p, img });
                  })
                  .catch((error: any) => {
                    resolve(p);
                  })
                }));
              }

              productImgsResolved = await Promise.all(productImgsPromises);
              for (const p of productImgsResolved) {
                if (p.img) {
                  productBlobsPromises.push(new Promise((resolve, reject) => {
                    p.img.blob()
                    .then((blob: any) => {
                      resolve({ ...p, blob });
                    })
                    .catch((error: any) => {
                      resolve(p);
                    })
                  }));
                }
                else {
                  productBlobsPromises.push(new Promise((resolve, reject) => { resolve(p); }));
                }
              }

              productBlobs = await Promise.all(productBlobsPromises);
              for (const p of productBlobs) {
                if (p && p.blob) {
                  productImagesPromises.push(new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      resolve({ ...p, base64: reader.result });
                    };
                    reader.onerror = () => {
                      resolve(p);
                    };
                    reader.readAsDataURL(p.blob);
                  }));
                }
                else {
                  productImagesPromises.push(new Promise((resolve, reject) => { resolve(p); }));
                }
              }
            }

            productImages = this.listaPreciosImagen ? await Promise.all(productImagesPromises) : arrayProducts;
            for (const producto of productImages) {
              if (Array.isArray(listadoProductosOrdenados[producto.familia])) {
                if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria])) {
                  if (Array.isArray(listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria])) {
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                  } else {
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                    listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                  }
                } else {
                  listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                  listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
                }
              } else {
                listadoProductosOrdenados[producto.familia] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria] = new Array();
                listadoProductosOrdenados[producto.familia][producto.categoria][producto.subcategoria].push(producto);
              }
            }
            c++;
            this.fileDownloadProgressValue = Math.min(Math.max((((c * 100) / totalImgsChunks) / 2), 0), 50).toFixed(0); // Clamp entre 0 y 50
          }
        } catch (error) {
          this.data.log('Error adding products at PDF download, exception:', error);
        }

        productImgsPromises = [];
        productBlobsPromises = [];
        productImagesPromises = [];
        productImgsResolved = [];
        productBlobs = [];
        productImages = [];

        const pdfModalMain = document.getElementById('pdf-modal-main');

        if (pdfModalMain) {
          let familiesToDelete = [
            "Bazar",
            "Textil",
            "Liquidos",
            "Jardin-y-riego",
            "Profesional",
            "Mas-productos",
          ];
          let currFamily = '';
          for (const key in listadoProductosOrdenados) {
            if (Object.prototype.hasOwnProperty.call(listadoProductosOrdenados, key)) {
              currFamily = key;
              familiesToDelete = familiesToDelete.filter((f) => f !== currFamily)
              pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                <p class="fs-2">${key}</p>
              </div>`);

              const familias = listadoProductosOrdenados[key];
              for (const key in familias) {
                if (Object.prototype.hasOwnProperty.call(familias, key)) {
                  pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                    <p class="fs-4">${key}</p>
                  </div>`);

                  const categorias = familias[key];
                  for (const key in categorias) {
                    if (Object.prototype.hasOwnProperty.call(categorias, key)) {
                      pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                        <p class="fs-6">${key}</p>
                      </div>`);

                      const subcategorias = categorias[key];
                      let rows = "";
                      for (const p of subcategorias) {
                        rows += `<tr>
                          <td>` + (this.listaPreciosImagen ? `<div class="flex justify-content-center align-items-center"><img src="${p.base64}" title="F" alt="F" width="24" height="16"></div>` : '') + `</td>
                          <td>${p.codigo_interno}</td>
                          <td>${p.nombre}</td>
                          <td>${p.codigo_barras}</td>
                          <td>${p.unidad_medida}</td>
                          <td>${this.precioUnico ? p.precio : p.precio_1}</td>
                          ${!this.precioUnico ? ('<td>' + p.precio_2 + '</td>') : ''}
                          <td style="text-align: center;">${p.cpuc === 'UP' ? '<img src=' + arrowUp + ' title="UP" alt="UP">' :
                            (p.cpuc === 'DOWN' ? '<img src=' + arrowDown + ' title="DOWN" alt="DOWN">' :
                              (p.cpuc === '-' ? '-' : 'NC'))}</td>
                        </tr>`;
                      }
                      pdfModalMain.querySelector(`#${currFamily.split(' ').join('-')}`)?.insertAdjacentHTML('beforeend', `<div>
                        <table>
                          <thead>
                            <tr>
                              <th>Foto</th>
                              <th>Código</th>
                              <th>Descripción</th>
                              <th>Código de barras</th>
                              <th>Unidad de medida</th>
                              <th>${this.precioUnico ? ('Precio'+(this.iva_usuario.includes('NO INCLUYE') ? ' + I.V.A.' : (this.iva_usuario.includes('INCLUYE') ? ' I.V.A. Incluido' : (this.iva_usuario.includes('FINAL') ? ' Final' : '')))) : 'Precio 1'}</th>
                              ${!this.precioUnico ? '<th>Precio 2</th>' : ''}
                              <th>CPUC</th>
                            </tr>
                          </thead>
                          <tbody>
                            ${rows}
                          </tbody>
                        </table>
                      </div>`);
                    }
                  }
                }
              }
            }
          }
          for (const f of familiesToDelete) {
            pdfModalMain.querySelector(`#${f.split(' ').join('-')}`)?.remove();
          }

          const doc = new jsPDF();
          const children = Array.from(pdfModalMain.children);
          let count = 1;
          for (const div of children) {
            count++;
            const canvas: HTMLCanvasElement = await html2canvas(div as HTMLElement, { useCORS: true, allowTaint: false, logging: true });
            document.body.insertAdjacentHTML('beforeend', '<div id="canvas-placeholder' + div.id + '" style="visibility: hidden"></div>');
            document.getElementById('canvas-placeholder' + div.id)?.append(canvas);
            const totalWidth = canvas.clientWidth * window.devicePixelRatio;
            const totalHeigth = canvas.clientHeight * window.devicePixelRatio;
            document.getElementById('canvas-placeholder' + div.id)?.remove();
            const pageHeight = totalWidth * (297/206);
            const ctx = canvas.getContext('2d');
            if (ctx) {
              let i = 0
              let currY = 0
              do {
                i++;
                const miniCanvasData = ctx.getImageData(0, 1 + currY, totalWidth, totalHeigth);
                currY += pageHeight;
                const newMiniCanvas = document.createElement('canvas');
                newMiniCanvas.width = totalWidth;
                newMiniCanvas.height = pageHeight;
                const newCtx = newMiniCanvas.getContext('2d');
                if (newCtx && miniCanvasData) {
                  newCtx.putImageData(miniCanvasData, 0, 0);
                  const img = newMiniCanvas.toDataURL("image/jpeg", 0.8);
                  // document.body.append(newMiniCanvas)
                  // const imgElement = document.createElement('img')
                  // imgElement.src = img
                  // document.body.append(imgElement)

                  try {
                    doc.addImage(img, 'JPEG', 2, 0, 206, 297);
                    doc.addPage();
                  } catch (error) {
                    this.data.log('descargarlistapdf error pdf:', error);
                  }
                }
              } while ((pageHeight * i) <= totalHeigth)
            }
            this.fileDownloadProgressValue = Math.min(Math.max(((100 * ((count-1) / children.length) / 2) + 50), 0), 100).toFixed(0); // Clamp entre 0 y 100
          }

          doc.setFontSize(11);
          doc.text('* CPUC: Comparación de precios de últimas compras. ( 15 días )', 10, 10);
          doc.text('* NC: No se realizó la comparación de precios porque la compra se realizó hace más de 15 días.', 10, 30);

          doc.save('Lista de frecuentes '+environment.APP_DOMAIN+' al ' + hoyString + '.pdf');
        }

        for (const key in document.querySelectorAll('#loaderFile')) {
          if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFile'), key)) {
            const element = document.querySelectorAll('#loaderFile')[key];
            (element as HTMLElement).style.display = 'none';
          }
        }
        for (const key in document.querySelectorAll('#loaderFileMsg')) {
          if (Object.prototype.hasOwnProperty.call(document.querySelectorAll('#loaderFileMsg'), key)) {
            const element = document.querySelectorAll('#loaderFileMsg')[key];
            (element as HTMLElement).style.display = 'none';
          }
        }

        this.showPDFModal = false;
      }
    })
    .catch(($error) => {
      this.data.log('descargarlistafrecuentespdf frecuentes error cuenta:', $error);
      this.showPDFModal = false;
    });
  }

  disableButton:boolean = false;
  solicitudCambioDatosDisabledProcesandoFlag:boolean = false;
  solicitudCambioDatosDisabledEnviadaFlag:boolean = false;
  solicitudCambioDatosDisabledFlag: boolean = false;
  solicitudCambioDatosDisabledErrorIncompletoFlag: boolean = false;
  solicitarCambioDatosDisabled() {
    if (!this.solicitudCambioDatosDisabledEnviadaFlag) {
      this.newRazonSocial = this.DatosUsuario.razonSocial;
      this.newCuit = this.DatosUsuario.cuit;
      this.newEmail = this.DatosUsuario.email;
      this.solicitudCambioDatosDisabledProcesandoFlag = false;
      this.solicitudCambioDatosDisabledEnviadaFlag = false;
      this.solicitudCambioDatosDisabledErrorIncompletoFlag = false;
      this.solicitudCambioDatosDisabledFlag = true;
    }
  }
  closeSolicitarCambioDatosDisabled(event: any) {
    if (event.target.className === 'modal__container') {
      this.solicitudCambioDatosDisabledCancelar();
    }
  }
  solicitudCambioDatosDisabledCancelar() {
    this.solicitudCambioDatosDisabledFlag = false;
  }
  solicitudCambioDatosDisabledEnviar() {
    this.solicitudCambioDatosDisabledProcesandoFlag = false;
    this.solicitudCambioDatosDisabledEnviadaFlag = false;
    this.solicitudCambioDatosDisabledErrorIncompletoFlag = false;
    if (this.newRazonSocial && this.newCuit && this.newEmail) {
      this.disableButton = true;
      if (this.strcmp(this.DatosUsuario.razonSocial, this.newRazonSocial) !== 0
      || this.strcmp(this.DatosUsuario.cuit, this.newCuit) !== 0
      || this.strcmp(this.DatosUsuario.email, this.newEmail) !== 0) {
        const body = new URLSearchParams();

        if (this.strcmp(this.DatosUsuario.razonSocial, this.newRazonSocial) !== 0) {
          body.set('new_razon_social', this.newRazonSocial);
        }
        if (this.strcmp(this.DatosUsuario.cuit, this.newCuit) !== 0) {
          body.set('new_cuit', this.newCuit);
        }
        if (this.strcmp(this.DatosUsuario.email, this.newEmail) !== 0) {
          body.set('new_email', this.newEmail);
        }

        this.solicitudCambioDatosDisabledProcesandoFlag = true;

        this.auth.post('cliente/actualizar_campos_disabled', body)
        .then(($response) => {
          this.data.log('updatedisabledcliente response cuenta:', $response);

          this.solicitudCambioDatosDisabledProcesandoFlag = false;
          this.solicitudCambioDatosDisabledEnviadaFlag = true;
          this.solicitudCambioDatosDisabledFlag = false;
        })
        .catch(($error) => {
          this.data.log('updatedisabledcliente error cuenta:', $error);

          this.disableButton = false;

          this.solicitudCambioDatosDisabledProcesandoFlag = false;
          this.solicitudCambioDatosDisabledFlag = false;
        });
      } else {
        this.solicitudCambioDatosDisabledProcesandoFlag = true;
        setTimeout(() => {
          this.solicitudCambioDatosDisabledProcesandoFlag = false;
          this.solicitudCambioDatosDisabledEnviadaFlag = true;
          this.solicitudCambioDatosDisabledFlag = false;
        }, 2000);
      }
    } else {
      this.solicitudCambioDatosDisabledErrorIncompletoFlag = true;
    }
  }
  strcmp(a: any, b: any) {
    if (a.toString().toLowerCase() < b.toString().toLowerCase()) return -1;
    if (a.toString().toLowerCase() > b.toString().toLowerCase()) return 1;
    return 0;
  }

  guardarDatos() {
    this.procesando_info = true;
    this.procesando_info_entrega = true;

    const body = new URLSearchParams();
    body.set('nombre_fantasia', this.DatosUsuario.nombreFantasia);
    body.set('telefono', this.DatosUsuario.telefono);
    body.set('telefono_celular', this.DatosUsuario.telefonoCelular);
    body.set('cod_categoria_iva', this.DatosUsuario.codCategoriaIva);
    body.set('domicilio_direccion', this.DatosUsuario.domicilio.direccion);
    body.set('domicilio_ciudad', this.DatosUsuario.domicilio.ciudad);
    body.set('domicilio_provincia', this.DatosUsuario.domicilio.provincia);
    body.set('domicilio_codigo_postal', this.DatosUsuario.domicilio.codPostal);
    body.set('facturacion_nombre_responsable', this.DatosUsuario.nombreResponsableFacturacion);
    body.set('facturacion_email', this.DatosUsuario.emailFacturacion);
    body.set('facturacion_telefono', this.DatosUsuario.telefonoFacturacion);
    body.set('descripcion', this.DatosUsuario.descripcion);
    body.set('recepcion_dias_horarios', this.DatosUsuario.recepcionDiasHorarios);
    body.set('contacto_por_telefono', this.DatosUsuario.contactoPorTelefono);
    body.set('contacto_por_whatsapp', this.DatosUsuario.contactoPorWhatsapp);
    body.set('contacto_por_mail', this.DatosUsuario.contactoPorMail);
    body.set('envio_domicilio_direccion', this.DatosUsuario.datosEnvio.domicilioEntrega.direccion);
    body.set('envio_domicilio_ciudad', this.DatosUsuario.datosEnvio.domicilioEntrega.ciudad);
    body.set('envio_domicilio_provincia', this.DatosUsuario.datosEnvio.domicilioEntrega.provincia);
    body.set('envio_domicilio_codigo_postal', this.DatosUsuario.datosEnvio.domicilioEntrega.codPostal);
    body.set('envio_telefono', this.DatosUsuario.datosEnvio.telefono);
    body.set('envio_cod_transporte', this.DatosUsuario.datosEnvio.codigoTransporte);
    body.set('envio_horario_entrega', this.DatosUsuario.recepcionDiasHorarios);
    body.set('envio_entrega_lunes', this.DatosUsuario.datosEnvio.entregaLunes);
    body.set('envio_entrega_martes', this.DatosUsuario.datosEnvio.entregaMartes);
    body.set('envio_entrega_miercoles', this.DatosUsuario.datosEnvio.entregaMiercoles);
    body.set('envio_entrega_jueves', this.DatosUsuario.datosEnvio.entregaJueves);
    body.set('envio_entrega_viernes', this.DatosUsuario.datosEnvio.entregaViernes);
    body.set('envio_entrega_sabado', this.DatosUsuario.datosEnvio.entregaSabado);

    this.data.log('guardardatos body cuenta:', body);

    this.procesando_info_ok = '';
    this.procesando_info_entrega_ok = '';

    this.auth.post('cliente/actualizar', body)
    .then(($response) => {
      this.data.log('updatecliente response cuenta:', $response);

      this.procesando_info = false;
      this.procesando_info_error = '';
      this.procesando_info_ok = $response.body.response_datos;

      this.procesando_info_entrega = false;
      this.procesando_info_entrega_error = '';
      this.procesando_info_entrega_ok = $response.body.response_envio;
    })
    .catch(($error) => {
      this.data.log('updatecliente error cuenta:', $error);

      this.procesando_info = false;
      this.procesando_info_error = '';

      this.procesando_info_entrega = false;
      this.procesando_info_entrega_error = '';

      try {
        Object.values($error.error.response_datos)?.forEach((element) => {
          this.procesando_info_error += element + ' ';
        });
      } catch ($throw) {
        this.data.log('updatecliente error cuenta:', $throw);
      }
    });
  }

  public revisarCantidad(e: any) {
    if (e.target && parseInt(e.target.value) < parseInt(e.target.min)) {
      e.target.value = e.target.min;
    }
  }

}
