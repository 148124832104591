export const environment = {
  production: true,
  APP_ENV: 'production',
  APP_NAME: 'Sina',
  APP_DOMAIN: 'Sina.com.ar',
  APP_API_BASE: 'https://apisina-v1.leren.com.ar/',
  APP_WEB_BASE: 'https://www.sina.com.ar/',
  APP_ADMIN_BASE: 'https://admin.sina.com.ar/',
  APP_MAILING_BASE: 'https://mailing.leren.com.ar/',
  APP_MAILING_SUBSCRIBE: true,
  APP_SATISFACCION_BASE: 'https://satisfaccion.sina.com.ar/',
};
