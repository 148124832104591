import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { FilterSection } from '../data';
import { MenuService } from '../menu.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { FacebookPixelService } from '../facebook-pixel.service';
import { SharedService } from '../shared.service';

const Filter1: FilterSection[] = [
  // first query result
  new FilterSection(
    {
      texto:  'Marca',
      link:   'link',
    },
    [
      {
        texto:  'Sina',
        link:   'link',
        id: 1,
      },
      {
        texto:  'Vileda',
        link:   'link',
        id: 2,
      },
    ],
  ),
  new FilterSection(
    {
      texto:  'Uso',
      link:   'link',
    },
    [
      {
        texto:  'Profesional',
        link:   'link',
        id: 1,
      },
      {
        texto:  'Domestico',
        link:   'link',
        id: 2,
      },
    ],
  ),
];

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public _success = new Subject<string>();
  public iva_usuario: string = '';
  loginStatus:    boolean = false;
  successMessage!: string;
  staticAlertClosed = false;
  listaResultados: any;
  listaOriginal: any;
  listaDefault: any;
  FilterItem: any = undefined;
  term = '';
  categoriaPadre: any = undefined;
  categoriaHijo: any = undefined;
  id_filtro = '';
  id_categoria: any[] = [];
  id_subcategoria = '';
  num_subcategoria = -1;
  listado_subcategorias: any;
  show_mensaje_ofertas: boolean = true;
  mensaje!: string;
  LinkList: any[] = [];
  public familiaActual: string = 'Limpieza';
  public ordenamiento: string = '';
  public showOrdenarMobile: boolean = false;


  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  constructor(
    private menu: MenuService,
    private auth: AutenticacionService,
    private data: SharedService,
    // private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService,
    @Optional() @Inject('serverUrl') protected serverUrl: string,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }
  }
  updateMenu(id: any, id2: any, padre: any) {
    if (!id && !id2 && !padre) {
      let cat = typeof window !== typeof undefined ? window.location.href : (environment.APP_WEB_BASE + this.serverUrl?.slice(1));
      if (cat.indexOf('/busqueda/') === -1) {
        if (cat.indexOf('/Limpieza') !== -1) {
          this.data.updatePageTitle('Productos de Limpieza por mayor, fabrica de productos de Limpieza', 'Encontrá la mayor variedad de Limpieza al por mayor y al mejor precio en '+environment.APP_DOMAIN);
        } else if (cat.indexOf('/Bazar') !== -1) {
          this.data.updatePageTitle('Productos de Bazar por mayor, fabrica de productos de Bazar', 'Encontrá la mayor variedad de Bazar al por mayor y al mejor precio en '+environment.APP_DOMAIN);
        } else if (cat.indexOf('/Textil') !== -1) {
          this.data.updatePageTitle('Productos de Textil por mayor, fabrica de productos de Textil', 'Encontrá la mayor variedad de Textil al por mayor y al mejor precio en '+environment.APP_DOMAIN);
        } else if (cat.indexOf('/Liquidos') !== -1) {
          this.data.updatePageTitle('Productos de Liquidos por mayor, fabrica de productos de Liquidos', 'Encontrá la mayor variedad de Liquidos al por mayor y al mejor precio en '+environment.APP_DOMAIN);
        } else if (cat.indexOf('/Jardin%20y%20riego') !== -1) {
          this.data.updatePageTitle('Productos de Jardin y riego por mayor, fabrica de productos de Jardin y riego', 'Encontrá la mayor variedad de Jardin y riego al por mayor y al mejor precio en '+environment.APP_DOMAIN);
        } else if (cat.indexOf('/Profesional') !== -1) {
          this.data.updatePageTitle('Productos de Linea profesional por mayor, fabrica de productos de Linea profesional', 'Encontrá la mayor variedad de Linea profesional al por mayor y al mejor precio en '+environment.APP_DOMAIN);
        } else if (cat.indexOf('/Mas%20productos') !== -1) {
          this.data.updatePageTitle('Productos de Mas productos por mayor, fabrica de productos de Mas productos', 'Encontrá la mayor variedad de Mas productos al por mayor y al mejor precio en '+environment.APP_DOMAIN);
        }
      } else {
        this.data.updatePageTitle();
      }
      return;
    }
    this.ordenamiento = '';
    this.categoriaPadre = padre ? padre.split('-').join(' ') : '';
    this.categoriaHijo = id2 ? id2.split('-').join(' ') : '';
    if (!id2) {
      this.categoriaHijo = typeof window !== typeof undefined ? ((window.location.href.indexOf('ofertas') !== -1) ? 'OFERTAS' : 'NOVEDADES') : (((environment.APP_WEB_BASE + this.serverUrl?.slice(1)).indexOf('ofertas') !== -1) ? 'OFERTAS' : 'NOVEDADES');
    } else if (id) {
      this.data.updatePageTitle((this.categoriaHijo ? this.categoriaHijo : this.categoriaPadre) + ', ' +
      (this.categoriaHijo ? this.categoriaHijo : this.categoriaPadre) + ' al por mayor | '+environment.APP_DOMAIN, 'Encontrá la mayor variedad de ' +
      (this.categoriaHijo ? this.categoriaHijo : this.categoriaPadre) + ' al por mayor y al mejor precio en '+environment.APP_DOMAIN);

      let $categoria: any;
      let $subcategoria: any;
      if (this.categoriaHijo && this.categoriaPadre) {
        $subcategoria  = this.categoriaHijo;
        $categoria     = this.categoriaPadre;
      } else if (this.categoriaHijo) {
        $categoria     = this.categoriaHijo;
      }
      let itemActualMenu: any;
      let itemActualMenuPadre: any;
      let itemActualMenuHijo: any;
      let padreEncabezado = $categoria;
      if (padreEncabezado.includes('   ')) {
        padreEncabezado = padreEncabezado.split('   ').join(' - ');
      }
      this.LinkList.forEach(($item: any) => {
        let incluyeItem = false;
        $item.links.forEach(($item_padre: any) => {
          if (padreEncabezado === $item_padre.head.texto) {
            incluyeItem = true;
            itemActualMenuPadre = $item_padre;
          }
        });
        if (incluyeItem) {
          itemActualMenu = $item;
        }
      });
      if ($subcategoria && itemActualMenuPadre) {
        itemActualMenuHijo = itemActualMenuPadre.items.findIndex(($item_hijo: any) => {
          return $item_hijo.texto === $subcategoria;
        });
      }
      if (itemActualMenu) {
        this.id_filtro = itemActualMenu.texto;
        this.listado_subcategorias = itemActualMenuPadre.items;
        this.id_categoria = [];
        this.id_categoria.push({link: itemActualMenuPadre.head.link, texto: itemActualMenuPadre.head.texto, listado_subcategorias: this.listado_subcategorias});
        this.num_subcategoria = itemActualMenuHijo;
      }
    } else {
      let cat = typeof window !== typeof undefined ? window.location.href : (environment.APP_WEB_BASE + this.serverUrl?.slice(1));
      if (cat.indexOf('/busqueda/') !== -1) {
        this.data.updatePageTitle(id2+', '+id2+' al por mayor', 'Encontrá la mayor variedad de '+id2+' al por mayor y al mejor precio en '+environment.APP_DOMAIN);
      }
    }
  }
  ngOnInit() {
    this.data.currentUser.pipe(takeUntil(this.destroy$)).subscribe(($user: any) => {
      if ($user && $user['c'] === '1') {
        this.iva_usuario = 'LOS PRECIOS SON UNITARIOS Y ESTÁN SUJETOS A SU CONDICIÓN HABITUAL';
      } else {
        if ($user) {
          switch ($user['codCategoriaIva']) {
            case 'CF': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'INR': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RSS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'RI': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS NO INCLUYEN IVA'; break;
            case 'EX': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'PCE': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'PCS': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            case 'EXE': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS SON FINALES'; break;
            case 'SNC': this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS INCLUYEN IVA'; break;
            default: this.iva_usuario = 'LOS PRECIOS UNITARIOS DETALLADOS NO INCLUYEN IVA';
          }
        }
      }
    });
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (this.menu.LinkList.length) {
        this.LinkList = this.menu.LinkList;
        this.updateMenu(params['id2'], params['id'], params['padre']);
      } else {
        this.menu.LinkList$.pipe(takeUntil(this.destroy$)).subscribe((LinkList) => {
          this.LinkList = LinkList;
          this.updateMenu(params['id2'], params['id'], params['padre']);
        });
      }
    });
    // subscribing to data on loginStatus
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.loginStatus = status;
        // subscribing to route params
        this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
          const id = (params['id2']);
          const id2 = (params['id']);

          let url_consulta = this.loginStatus ? '' : 'public/';
          this.mensaje = 'Cargando';
          // Client only code.
          if (this.clientOnlyRun) {
            new Promise((resolve, reject) => {
              if (!id2) {
                const path = typeof window !== typeof undefined ? window.location.href : (environment.APP_WEB_BASE + this.serverUrl?.slice(1));
                // url_consulta += (window.location.href.indexOf('ofertas') !== -1) ? 'producto/listado/ofertas/' :
                // (window.location.href.indexOf('novedades') !== -1) ? 'producto/listado/novedades' : '';
                if (path.indexOf('nuestros-productos') !== -1) {
                  url_consulta += 'producto/listado/sina';
                } else if (path.indexOf('ofertas-y-novedades') !== -1) {
                  url_consulta += 'producto/listado/campania/';
                } else if (path.indexOf('ofertas-semanales') !== -1) {
                  url_consulta += 'producto/listado/ofertassemanales/';
                } else if (path.indexOf('ofertas') !== -1) {
                  url_consulta += 'producto/listado/ofertas/';
                } else if (path.indexOf('novedades') !== -1) {
                  url_consulta += 'producto/listado/novedades';
                } else if (path.indexOf('sustentables') !== -1) {
                  url_consulta += 'producto/listado/sustentables';
                } else if (path.indexOf('Limpieza') !== -1) {
                  url_consulta += 'producto/categoriapadre/Limpieza';
                  this.id_filtro = 'Limpieza';
                } else if (path.indexOf('Bazar') !== -1) {
                  url_consulta += 'producto/categoriapadre/Bazar';
                  this.id_filtro = 'Bazar';
                } else if (path.indexOf('Textil') !== -1) {
                  url_consulta += 'producto/categoriapadre/Textil';
                  this.id_filtro = 'Textil';
                } else if (path.indexOf('Liquidos') !== -1) {
                  url_consulta += 'producto/categoriapadre/Liquidos';
                  this.id_filtro = 'Liquidos';
                } else if (path.indexOf('Jardin%20y%20riego') !== -1) {
                  url_consulta += 'producto/categoriapadre/Jardin-y-riego';
                  this.id_filtro = 'Jardin y riego';
                } else if (path.indexOf('Profesional') !== -1) {
                  url_consulta += 'producto/categoriapadre/Profesional';
                  this.id_filtro = 'Profesional';
                } else if (path.indexOf('Mas%20productos') !== -1) {
                  url_consulta += 'producto/categoriapadre/Mas-productos';
                  this.id_filtro = 'Mas productos';
                } else {
                  url_consulta += '';
                  reject('Empty url.');
                }
                this.auth.get(url_consulta)
                .then(($res) => resolve($res.response))
                .catch(($error) => reject($error));
              } else if (id) {
                  this.auth.get(url_consulta + 'producto/categoria/' + (+id))
                    .then(($res) => {
                      resolve($res.response);
                    })
                    .catch(($error) => reject($error));
              } else {
                if (typeof window !== typeof undefined) {
                  if (window.location.href.includes('busqueda')) {
                    const body = new URLSearchParams();
                    body.set('frase', id2);
                    this.auth.post(url_consulta + 'producto/busqueda/', body)
                      .then(($res) => resolve($res.body.response)).catch(($error) => reject($error));
                  }
                } else {
                  if ((environment.APP_WEB_BASE + this.serverUrl?.slice(1)).includes('busqueda')) {
                    const body = new URLSearchParams();
                    body.set('frase', id2);
                    this.auth.post(url_consulta + 'producto/busqueda/', body)
                      .then(($res) => resolve($res.body.response)).catch(($error) => reject($error));
                  }
                }
              }
            }).then(($response: any)  => {
              this.listaResultados = $response.sort((a: any, b: any) => b.enStock - a.enStock);
              this.listaOriginal = $response.sort((a: any, b: any) => b.enStock - a.enStock);
              this.listaDefault = $response.slice().sort((a: any, b: any) => b.enStock - a.enStock);
              this.paginado.init();

              this.route.queryParams.subscribe(queryParams => {
                if (queryParams && queryParams.p) {
                  this.paginado.goto(Number(queryParams.p));
                } else {
                  this.paginado.goto(1);
                }
              });

              setTimeout(() => {

                const path = typeof window !== typeof undefined ? window.location.href : (environment.APP_WEB_BASE + this.serverUrl?.slice(1));
                if (path.indexOf('/busqueda/') !== -1) {
                  if (this.listaDefault && this.listaDefault.length <= 48) {
                    this.paginado.disable(); // Para poner listado por default, en lugar de paginado
                  } else {
                    this.modoVista = 'Paginado';
                  }
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Ofertas';

                  this.id_categoria.push({link: '/nuestros-productos', texto: 'Nuestros productos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas', texto: 'Ofertas', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-semanales', texto: 'Ofertas Semanales', listado_subcategorias: null});
                  this.id_categoria.push({link: '/novedades', texto: 'Novedades', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-y-novedades', texto: 'Más vendidos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/sustentables', texto: 'Sustentables', listado_subcategorias: null});
                } else if (path.indexOf('nuestros-productos') !== -1) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Nuestros productos';

                  this.id_categoria.push({link: '/nuestros-productos', texto: 'Nuestros productos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas', texto: 'Ofertas', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-semanales', texto: 'Ofertas Semanales', listado_subcategorias: null});
                  this.id_categoria.push({link: '/novedades', texto: 'Novedades', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-y-novedades', texto: 'Más vendidos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/sustentables', texto: 'Sustentables', listado_subcategorias: null});
                } else if (path.indexOf('ofertas-y-novedades') !== -1) {
                  this.paginado.disable(); // Para poner listado por default, en lugar de paginado
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Más vendidos';

                  this.id_categoria.push({link: '/nuestros-productos', texto: 'Nuestros productos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas', texto: 'Ofertas', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-semanales', texto: 'Ofertas Semanales', listado_subcategorias: null});
                  this.id_categoria.push({link: '/novedades', texto: 'Novedades', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-y-novedades', texto: 'Más vendidos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/sustentables', texto: 'Sustentables', listado_subcategorias: null});
                } else if (path.indexOf('novedades') !== -1) {
                  this.paginado.disable(); // Para poner listado por default, en lugar de paginado
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Novedades';

                  this.id_categoria.push({link: '/nuestros-productos', texto: 'Nuestros productos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas', texto: 'Ofertas', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-semanales', texto: 'Ofertas Semanales', listado_subcategorias: null});
                  this.id_categoria.push({link: '/novedades', texto: 'Novedades', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-y-novedades', texto: 'Más vendidos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/sustentables', texto: 'Sustentables', listado_subcategorias: null});
                } else if (path.indexOf('ofertas-semanales') !== -1) {
                  this.paginado.disable(); // Para poner listado por default, en lugar de paginado
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Ofertas Semanales';

                  this.id_categoria.push({link: '/nuestros-productos', texto: 'Nuestros productos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas', texto: 'Ofertas', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-semanales', texto: 'Ofertas Semanales', listado_subcategorias: null});
                  this.id_categoria.push({link: '/novedades', texto: 'Novedades', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-y-novedades', texto: 'Más vendidos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/sustentables', texto: 'Sustentables', listado_subcategorias: null});
                } else if (path.indexOf('ofertas') !== -1) {
                  this.paginado.disable(); // Para poner listado por default, en lugar de paginado
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Ofertas';

                  this.id_categoria.push({link: '/nuestros-productos', texto: 'Nuestros productos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas', texto: 'Ofertas', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-semanales', texto: 'Ofertas Semanales', listado_subcategorias: null});
                  this.id_categoria.push({link: '/novedades', texto: 'Novedades', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-y-novedades', texto: 'Más vendidos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/sustentables', texto: 'Sustentables', listado_subcategorias: null});
                } else if (path.indexOf('sustentables') !== -1) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Sustentables';

                  this.id_categoria.push({link: '/nuestros-productos', texto: 'Nuestros productos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas', texto: 'Ofertas', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-semanales', texto: 'Ofertas Semanales', listado_subcategorias: null});
                  this.id_categoria.push({link: '/novedades', texto: 'Novedades', listado_subcategorias: null});
                  this.id_categoria.push({link: '/ofertas-y-novedades', texto: 'Más vendidos', listado_subcategorias: null});
                  this.id_categoria.push({link: '/sustentables', texto: 'Sustentables', listado_subcategorias: null});
                } else if (path.indexOf('Limpieza') !== -1 && this.LinkList) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Limpieza';
                  for (const subcat of this.LinkList[0].links) {
                    this.id_categoria.push({link: subcat.head.link, texto: subcat.head.texto, listado_subcategorias: subcat.items});
                  }
                } else if (path.indexOf('Bazar') !== -1 && this.LinkList) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Bazar';
                  for (const subcat of this.LinkList[1].links) {
                    this.id_categoria.push({link: subcat.head.link, texto: subcat.head.texto, listado_subcategorias: subcat.items});
                  }
                } else if (path.indexOf('Textil') !== -1 && this.LinkList) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Textil';
                  for (const subcat of this.LinkList[2].links) {
                    this.id_categoria.push({link: subcat.head.link, texto: subcat.head.texto, listado_subcategorias: subcat.items});
                  }
                } else if (path.indexOf('Liquidos') !== -1 && this.LinkList) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Liquidos';
                  for (const subcat of this.LinkList[3].links) {
                    this.id_categoria.push({link: subcat.head.link, texto: subcat.head.texto, listado_subcategorias: subcat.items});
                  }
                } else if (path.indexOf('Jardin%20y%20riego') !== -1 && this.LinkList) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Jardin y riego';
                  for (const subcat of this.LinkList[4].links) {
                    this.id_categoria.push({link: subcat.head.link, texto: subcat.head.texto, listado_subcategorias: subcat.items});
                  }
                } else if (path.indexOf('Profesional') !== -1 && this.LinkList) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Linea profesional';
                  for (const subcat of this.LinkList[5].links) {
                    this.id_categoria.push({link: subcat.head.link, texto: subcat.head.texto, listado_subcategorias: subcat.items});
                  }
                } else if (path.indexOf('Mas%20productos') !== -1 && this.LinkList) {
                  this.modoVista = 'Paginado';
                  this.num_subcategoria = -2;
                  this.id_categoria = [];
                  this.familiaActual = 'Mas productos';
                  for (const subcat of this.LinkList[6].links) {
                    this.id_categoria.push({link: subcat.head.link, texto: subcat.head.texto, listado_subcategorias: subcat.items});
                  }
                } else {
                  this.paginado.disable(); // Para poner listado por default, en lugar de paginado
                }

                if (!this.listaResultados.length) {
                  this.mensaje = 'No hay resultado para la consulta';
                } else {
                  this.mensaje = '';
                }
              }, 1000);
            })
            .catch(($error) => {
              this.data.log('getlistadoofertas error filter for: ', url_consulta, ', error: ', $error);
              this.listaResultados = [];
              this.mensaje = 'No hay resultado para la consulta';
            });
          }
        });
      },
    );

    this.FilterItem = Filter1;
    this.FilterItem.forEach((element: any) => {
      element.head.selected = 0;
      element.head.state    = false;
    });
    this.FilterItem[0].head.state = true;

    setTimeout(() => this.staticAlertClosed = true, 5000);
    this._success.pipe(takeUntil(this.destroy$)).subscribe((message) => this.successMessage = message);
    this._success.pipe(takeUntil(this.destroy$), debounceTime(5000)).subscribe(() => this.successMessage = '');
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  alertClicked() {
    this.successMessage = '';
    this.data.toggleCarritoShow();
  }

  itemFilterClick(index: any) {
    this.FilterItem[index].head.state = this.FilterItem[index].head.state ? false : true;
  }
  selectItemClick(section: any, item: any) {
    this.FilterItem[section].head.selected      = this.FilterItem[section].items[item].id;
    this.FilterItem[section].head.selectedText  = this.FilterItem[section].items[item].texto;
    this.term = this.FilterItem[section].items[item].texto;
  }
  itemFilterRemoveClick(index: any) {
    this.FilterItem[index].head.state    = false;
    this.FilterItem[index].head.selected = 0;
    this.term = '';
  }

  newMessage(msg: any) {
    if (this.loginStatus === true) {
      if (msg.cantidad) {
        if ((+msg.cantidad % +msg.cantPack === 0 && +msg.cantidad > +msg.cantMinima) || (+msg.cantMinima === +msg.cantidad)) {
          const body = new URLSearchParams();
          body.set('id_producto', msg.id);
          body.set('cantidad', msg.cantidad);
          this.auth.post('carrito/agregar_item', body)
          .then(($response) => {
            if (environment.production) {
              this.googleAnalyticsService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
              this.facebookPixelService.addToCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
            }
            this.data.log('response carritoagregaritem filter', $response);

            const response = this.data.addMessage(msg);
            if (response.value) {
              this._success.next(response.text);
            }
          })
          .catch(($error) => {
            this.data.log('error carritoagregaritem filter', $error);
          });
        } else {
          msg['incompleto'] = true;
        }
      }
    } else {
      this.data.toggleLoginModal();
    }
  }
  removeMessage(msg: any) {
    if (this.loginStatus === true) {
      const body = new URLSearchParams();
      body.set('id_producto', msg.id);
      this.auth.post('carrito/eliminar_item', body)
      .then(($response) => {
        if (environment.production) {
          this.googleAnalyticsService.removeFromCart({ sku: msg.codInterno, descripcion: msg.titulo, categoria: msg.categorias ? msg.categorias[0]?.nombre : undefined, precio: msg.precio, cantidad: msg.cantidad });
        }
        this.data.log('response carritoeliminaritem compra', $response);
        this.data.removeMessage(msg);
        msg.comprado = false;
      })
      .catch(($error) => {
        this.data.log('error carritoeliminaritem compra', $error);
      });
    } else {
      this.data.toggleLoginModal();
    }
  }

  paginado = {
    cantidad: 20,
    pagina: 0,
    maximo: 0,
    original: [],
    init: (page: number = 1) => {
      this.paginado.pagina = page;
      this.paginado.maximo = Math.trunc(this.listaResultados.length / this.paginado.cantidad);
      if (this.listaResultados.length % this.paginado.cantidad) {
        this.paginado.maximo++;
      }
      this.paginado.original = this.listaResultados;
      this.listaResultados = this.paginado.original.slice(0, this.paginado.cantidad);
    },
    disable: () => {
      this.listaResultados = this.paginado.original.slice(0, this.paginado.original.length);
    },
    actualizar: () => {
      this.listaResultados = this.paginado.original.slice(this.paginado.cantidad * (this.paginado.pagina - 1), this.paginado.cantidad * this.paginado.pagina);
    },
    siguiente: () => {
      if (this.paginado.pagina < this.paginado.maximo) this.router.navigate([], { queryParams: { p: this.paginado.pagina + 1 } });
      // if (this.paginado.pagina < this.paginado.maximo) { this.paginado.pagina ++; }
      // this.paginado.actualizar();
    },
    anterior: () => {
      if (this.paginado.pagina > 1) this.router.navigate([], { queryParams: { p: this.paginado.pagina - 1 } });
      // if (this.paginado.pagina > 1) { this.paginado.pagina --; }
      // this.paginado.actualizar();
    },
    goto: (page: number) => {
      if (page >= 1 && page <= this.paginado.maximo) {
        this.paginado.pagina = page;
      } else {
        this.paginado.pagina = 1;
      }
      this.paginado.actualizar();
    }
  };

  modoVista: string = 'Listado'; // Cambio default de Paginado a Listado
  cambiarVista($vista: any) {
    this.modoVista = $vista;
    if ($vista === 'Paginado') {
      this.paginado.actualizar();
    } else {
      this.paginado.disable();
    }

    if (this.ordenamiento && this.ordenamiento.startsWith('nombre')) {
      this.ordenPorNombre();
    } else if (this.ordenamiento && this.ordenamiento.startsWith('precio')) {
      this.ordenPorPrecio();
    } else {
      this.ordenPorDefecto();
    }
  }

  ordenPorNombre() {
    if (this.modoVista && this.modoVista === 'Paginado') {
      if (this.ordenamiento && this.ordenamiento === 'nombreDESCP') {
        this.ordenamiento = 'nombreASCP';
        this.paginado.original = this.listaOriginal.sort((a: any, b: any) => { if (a.titulo === b.titulo) {return 0; } else {return a.titulo < b.titulo ? 1 : -1; } });
        this.paginado.actualizar();
      } else {
        this.ordenamiento = 'nombreDESCP';
        this.paginado.original = this.listaOriginal.sort((a: any, b: any) => { if (a.titulo === b.titulo) {return 0; } else {return a.titulo > b.titulo ? 1 : -1; } });
        this.paginado.actualizar();
      }
    }
    if (this.modoVista && this.modoVista === 'Listado') {
      if (this.ordenamiento && this.ordenamiento === 'nombreDESCL') {
        this.ordenamiento = 'nombreASCL';
        this.listaResultados = this.listaResultados.sort((a: any, b: any) => { if (a.titulo === b.titulo) {return 0; } else {return a.titulo < b.titulo ? 1 : -1; } });
      } else {
        this.ordenamiento = 'nombreDESCL';
        this.listaResultados = this.listaResultados.sort((a: any, b: any) => { if (a.titulo === b.titulo) {return 0; } else {return a.titulo > b.titulo ? 1 : -1; } });
      }
    }
    this.toggleOrdenarMobile();
  }
  ordenPorPrecio() {
    if (this.modoVista && this.modoVista === 'Paginado') {
      if (this.ordenamiento && this.ordenamiento === 'precioDESCP') {
        this.ordenamiento = 'precioASCP';
        this.paginado.original = this.listaOriginal.sort((a: any, b: any) => b.precio - a.precio);
        this.paginado.actualizar();
      } else {
        this.ordenamiento = 'precioDESCP';
        this.paginado.original = this.listaOriginal.sort((a: any, b: any) => a.precio - b.precio);
        this.paginado.actualizar();
      }
    }
    if (this.modoVista && this.modoVista === 'Listado') {
      if (this.ordenamiento && this.ordenamiento === 'precioDESCL') {
        this.ordenamiento = 'precioASCL';
        this.listaResultados = this.listaResultados.sort((a: any, b: any) => b.precio - a.precio );
      } else {
        this.ordenamiento = 'precioDESCL';
        this.listaResultados = this.listaResultados.sort((a: any, b: any) => a.precio - b.precio );
      }
    }
    this.toggleOrdenarMobile();
  }
  ordenPorDefecto() {
    if (this.modoVista && this.modoVista === 'Paginado') {
      this.ordenamiento = '';
      this.paginado.original = this.listaDefault.slice();
      this.paginado.actualizar();
    }
    if (this.modoVista && this.modoVista === 'Listado') {
      this.ordenamiento = '';
      this.listaResultados = this.listaDefault.slice();
    }
    this.toggleOrdenarMobile();
  }
  toggleOrdenarMobile() {
    this.showOrdenarMobile = !this.showOrdenarMobile;
  }

}
