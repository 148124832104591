import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss']
})
export class CategoriasComponent implements OnInit {

  environment: any;
  constructor(private data: SharedService) {
    this.environment = environment;
  }

  ngOnInit() {
    this.data.updatePageTitle('Productos de limpieza por mayor, fabrica de productos de limpieza');
  }

}
