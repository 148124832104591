<div class="sidenavMenu">
  <div class="top">
      <div class="row px-3 pt-2 pb-1 align-items-center">
          <div class="col-10">
              <a class="navbar-brand" [routerLink]="['/']" (click)="toggleSideBar()">
                  <img width="111" height="34" [ngClass]="'lazyload'" data-src="./assets/images/header/logo.webp" alt="Sina">
              </a>
          </div>
          <div class="col-2">
              <div class="menu" [ngClass]="SideStateIcon">
                  <i (click)="toggleMenu()" class="fa fa-bars" aria-hidden="true" style="font-size: 25px; color: #fff; margin-top: 5px;"></i>          
              </div>  
          </div>
      </div>
      <div class="row" style="justify-content: space-around; padding: 5px;">
          <div class="telefono w-auto">
              <div (click)="toggleSideBar()" style="    
                  cursor: pointer;
                  background: #0083ff;
                  border-radius: 25px;
                  margin: 0px 5px;
                  padding: 8px;">
                  <a href="tel:01121399100" target="_blank" rel="noopener noreferrer">
                      <img width="24" height="24" [ngClass]="'lazyload'" data-src="./assets/images/header/phone.webp" alt="telefono">
                  </a>
              </div>
          </div>
          <div (click)="toggleSideBar()" class="whatsapp w-auto">
              <div style="    
                  cursor: pointer;
                  background: #0083ff;
                  border-radius: 25px;
                  padding: 8px;">
                  <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5491153073468">
                    <img width="24" height="24" [ngClass]="'lazyload'" data-src="./assets/images/header/whatsapp.webp" alt="whatsapp">
                  </a>
              </div>
          </div>
          <div class="email w-auto">
              <div (click)="toggleSideBar()" style="    
                  cursor: pointer;
                  background: #0083ff;
                  border-radius: 25px;
                  margin: 0px 5px;
                  padding: 8px;" routerLink="contacto">
                    <img width="24" height="24" [ngClass]="'lazyload'" data-src="./assets/images/header/email.webp" alt="email">
              </div>
          </div>
      </div>
      <div class="row listado">
          <ul class="items" *ngIf="clientOnlyRun">
              <li *ngFor="let item of LinkList; let itemIndex = index" class="nav-item" style="min-height: 51px;">
                  <a class="nav-link" (click)="changeStyleClick(itemIndex + 1)" style="margin: auto;">
                      <img width="30" height="30" [ngClass]="'lazyload'" [attr.data-src]="item.img1" alt="">
                      <p style="width: 45%;">{{item.texto}}</p><span style="float: right; padding-top: 5px; min-width: 15px;">&#8827;</span>
                  </a>
                  <div class="subcategorias column" [ngClass]="{'toggleSubCategorias': (LinkIndex === (itemIndex + 1))}">
                      <a class="nav-link" (click)="changeStyleClick(itemIndex + 1)">Volver</a>
                      <div class="section" *ngFor="let section of item.links; let ItemIndexLink = index;">
                          <a *ngIf="(LinkIndexHija === 0) && (section.items.length === 0)" (click)="changeStyleClickHija(ItemIndexLink + 1, section)" [routerLink]="section.head.link" class="title" style="
                              width: 90%;
                              display: inline-block;
                              text-overflow: ellipsis;
                              overflow: hidden;
                              white-space: nowrap;
                              "
                          >{{section.head.texto}}</a><span *ngIf="(LinkIndexHija === 0) && (section.items.length === 0)" style="float: right; min-width: 15px; color: rgb(51, 122, 183);">&#8827;</span>
                          <a *ngIf="(LinkIndexHija === 0) && (section.items.length !== 0)" (click)="changeStyleClickHija(ItemIndexLink + 1, section)" class="title" style="
                              width: 90%;
                              display: inline-block;
                              text-overflow: ellipsis;
                              overflow: hidden;
                              white-space: nowrap;
                              "
                          >{{section.head.texto}}</a><span *ngIf="(LinkIndexHija === 0) && (section.items.length !== 0)" style="float: right; min-width: 15px; color: rgb(51, 122, 183);">&#8827;</span>
                          <!--div *ngFor="let itemHija of section.items; let ItemIndexHija = index;" class="subcategorias column" [ngClass]="{'toggleSubCategorias__hija': (LinkIndexHija === (ItemIndexHija + 1))}" style="top: 0px;"-->        
                          <div *ngFor="let itemHija of section.items; let ItemIndexHija = index;" class="subcategorias column" [ngClass]="{'toggleSubCategorias__hija': (LinkIndexHija === (ItemIndexLink + 1))}" style="top: 0px;">
                              <a class="nav-link" (click)="closeHija()">Volver</a>
                              <div class="section" *ngFor="let itemHija of section.items;" >
                                  <a (click)="toggleSideBar()" [routerLink]="itemHija?.link" class="title" style="
                                          width: 90%;
                                          display: inline-block;
                                          text-overflow: ellipsis;
                                          overflow: hidden;
                                          white-space: nowrap;
                                      ">{{itemHija.texto}}</a><span style="float: right; min-width: 15px; color: rgb(51, 122, 183);">&#8827;</span>
                              </div>
                              <div class="section">
                                  <a (click)="toggleSideBar()" [routerLink]="section.head?.link" class="title" style="
                                          width: 90%;
                                          display: inline-block;
                                          text-overflow: ellipsis;
                                          overflow: hidden;
                                          white-space: nowrap;
                                          color: black;
                                  ">VER TODO</a><span style="float: right; min-width: 15px; color: rgb(51, 122, 183);">&#8827;</span>
                              </div>
                              <li class="nav-item">
                                  <a class="nav-link" routerLink=""></a>
                              </li>
                          </div>                        
                      </div>
                      <div class="section">
                          <a (click)="toggleSideBar()" routerLink="/{{item.texto}}" class="title" style="
                                  width: 90%;
                                  display: inline-block;
                                  text-overflow: ellipsis;
                                  overflow: hidden;
                                  white-space: nowrap;
                                  color: black;
                          ">VER TODO</a><span style="float: right; min-width: 15px; color: rgb(51, 122, 183);">&#8827;</span>
                      </div>
                  </div>
              </li>
          </ul>
      </div>
      
  </div>
  <div class="container subheader">
    <div class="sub-menu">
        <mat-toolbar color="primary">
        <mat-menu #appOfertas="matMenu">
            <button mat-menu-item>
            <a class="text-decoration-none" routerLink="/ofertas">
                Ofertas
            </a>
            </button>
            <button mat-menu-item>
            <a class="text-decoration-none" routerLink="/ofertas-semanales">
                Ofertas Semanales
            </a>
            </button>
            <button mat-menu-item>
            <a class="text-decoration-none" routerLink="/novedades">
                Novedades
            </a>
            </button>
            <button mat-menu-item>
            <a class="text-decoration-none" routerLink="/ofertas-y-novedades">
                Ofertas y Novedades
            </a>
            </button>
            <button mat-menu-item>
            <a class="text-decoration-none" routerLink="/sustentables">
                Sustentables
            </a>
            </button>
        </mat-menu>
        <button class="sub-menu-button" mat-button [matMenuTriggerFor]="appOfertas">Ofertas y Novedades <i class="fa fa-caret-down"></i></button>
        </mat-toolbar>
      <mat-toolbar color="primary">
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item *ngFor="let item of LinkListSubMenu">
            <a class="text-decoration-none" routerLink="{{item.url}}">
              {{item.texto}}
            </a>
          </button>
          <button mat-menu-item>
            <a class="text-decoration-none" (click)="registrar()">
              Nuevo cliente
            </a>
          </button>
          <button mat-menu-item>
            <a class="text-decoration-none" routerLink="preguntas-frecuentes">
              Preguntas frecuentes
            </a>
          </button>
          <button mat-menu-item>
            <a class="text-decoration-none" routerLink="contacto">
              Contacto
            </a>
          </button>
        </mat-menu>
        <button class="sub-menu-button" mat-button [matMenuTriggerFor]="appMenu">Conocé más <i class="fa fa-caret-down"></i></button>
      </mat-toolbar>
    </div>
  </div>

  <div class="sesion">
      <div class="cuenta" [routerLink]="['/cuenta']" *ngIf="UserLog" (click)="toggleSideBar()">
          <img width="40" height="40" [ngClass]="'lazyload'" data-src="./assets/images/header/user__verde.webp" alt="User">
          <p style="color: #fff; 
          max-width: 130px;
          width: 100px;
          margin: 0px 10px;
          line-height: 14px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;

          " *ngIf="UserLog"> {{UserName || "Iniciar sesión"}} <br> {{UserJob || "Registrarse"}} </p>
      </div>
      <div class="cuenta" (click)="registrar()" *ngIf="!UserLog" style="width: calc(100% - 40px);">
          <div [ngClass]="{'pulsating_white': !UserLog}"></div>
          <img width="40" height="40" [ngClass]="'lazyload'" data-src="./assets/images/header/user__verde.webp" alt="User" style="z-index: 2;">
          <p style="z-index: 2;">Iniciar sesión <br> Registrarse</p>
      </div>
      <div class="cerrar" *ngIf="UserLog" (click)="sidebarCerrarSesion()">
          <p> Cerrar sesión </p>
      </div>
  </div>
</div>
