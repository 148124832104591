import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoriasComponent } from './categorias/categorias.component';
import { CompraComponent } from './compra/compra.component';
import { ConfirmacionComponent } from './confirmacion/confirmacion.component';
import { ConfirmarDatosComponent } from './confirmar-datos/confirmar-datos.component';
import { CuentaComponent } from './cuenta/cuenta.component';
import { FilterComponent } from './filter/filter.component';
import { HomeComponent } from './home/home.component';
import { ProductoComponent } from './producto/producto.component';
import { RecuperarPassComponent } from './recuperar-pass/recuperar-pass.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'registrate',
    component: HomeComponent,
  },
  {
    path: 'confirmacion',
    component: ConfirmacionComponent,
    data: { id: 1 },
  },
  {
    path: 'confirmar_datos_cliente',
    component: ConfirmarDatosComponent,
    data: { id: 1 },
  },
  {
    path: 'recuperar_contrasena',
    component: RecuperarPassComponent,
    data: { id: 1 },
  },
  {
    path: 'politica-privacidad',
    loadChildren: () => import('./politica-privacidad/politica-privacidad.module').then(m => m.PoliticaPrivacidadModule)
  },
  {
    path: 'preguntas-frecuentes',
    loadChildren: () => import('./preguntas-frecuentes/preguntas-frecuentes.module').then(m => m.PreguntasFrecuentesModule)
  },
  {
    path: 'como-comprar',
    loadChildren: () => import('./como-comprar/como-comprar.module').then(m => m.ComoComprarModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./contacto/contacto.module').then(m => m.ContactoModule)
  },
  {
    path: 'sumate',
    loadChildren: () => import('./sumate/sumate.module').then(m => m.SumateModule)
  },
  {
    path: 'envios',
    loadChildren: () => import('./envios/envios.module').then(m => m.EnviosModule)
  },
  {
    path: 'nosotros',
    loadChildren: () => import('./nosotros/nosotros.module').then(m => m.NosotrosModule)
  },
  {
    path: 'busqueda/:id',
    component: FilterComponent,
  },
  {
    path: 'ofertas',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: 'novedades',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: 'Limpieza',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: 'Bazar',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: 'Textil',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: 'Liquidos',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: 'Jardin y riego',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: 'Profesional',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: 'Mas productos',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: ':algo/:algo2/:id/:id2',
    component: ProductoComponent,
  },
  {
    path: 'cuenta',
    component: CuentaComponent,
    data: { id: 1 },
  },
  {
    path: 'compra/carrito',
    component: CompraComponent,
    data: { id: 1 },
  },
  {
    path: 'compra/envio',
    component: CompraComponent,
    data: { id: 1 },
  },
  {
    path: 'compra/finalizada',
    component: CompraComponent,
    data: { id: 1 },
  },
  {
    path: 'productos',
    component: CategoriasComponent,
    data: { title: 'Other text' },
  },
  {
    path: ':id/:id2',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  {
    path: ':padre/:id/:id2',
    component: FilterComponent,
    data: { title: 'Other text' },
  },
  { path: '**', component: FilterComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
