<div class="modal__container"  >
  <div class="modal-dialog">

    <div class="modal-content">
    <div class="modal-body">
        <div class="row" style="display: flex; align-items: center;" >
          <div class="row" style="padding: 50px;">
            Subiendo información, por favor espere.... 
          </div>
        </div>
      </div>                          
</div>
</div>