import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

declare const fbq: (a?: any, b?: any, c?: any) => void;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {
  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }
  }

  public async registroExitoso() {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          content_name: 'registro',
          status: true,
        }

        // console.log('FacebookPixel: sending "CompleteRegistration" fbq event. Payload:', payload)

        fbq('track', 'CompleteRegistration', payload);
      } catch (error) {
        console.log('FacebookPixel: error sending "exitoso" fbq event.', error)
      }
    }
  }

  public async viewItem(item: any) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          content_ids: [item.sku],
          content_category: item.categoria,
          content_name: item.descripcion,
          content_type: 'product',
          contents: [{ 'id': item.sku, 'quantity': item.cantidad ? parseInt(item.cantidad, 10) : 1 }],
          currency: 'ARS',
          value: item.precio ? parseFloat(item.precio) : undefined,
        }

        // console.log('FacebookPixel: sending "ViewContent" fbq event. Payload:', payload)

        fbq('track', 'ViewContent', payload);
      } catch (error) {
        console.log('FacebookPixel: error sending "ViewContent" fbq event.', error)
      }
    }
  }

  public async addToCart(item: any) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          content_ids: [item.sku],
          content_name: item.descripcion,
          content_type: 'product',
          contents: [{ 'id': item.sku, 'quantity': item.cantidad ? parseInt(item.cantidad, 10) : 1 }],
          currency: 'ARS',
          value: parseFloat(item.precio) * parseInt(item.cantidad, 10),
        }

        // console.log('FacebookPixel: sending "AddToCart" fbq event. Payload:', payload)

        fbq('track', 'AddToCart', payload)
      } catch (error) {
        console.log('FacebookPixel: error sending "AddToCart" fbq event.', error)
      }
    }
  }

  public async beginCheckout(productos: any) {
    if (this.clientOnlyRun) {
      try {
        let total = 0
        for (const i of productos) {
          total += parseFloat(i.precio) * parseInt(i.cantidad, 10)
        }

        const payload = {
          content_category: 'sina',
          content_ids: productos.map((i: any) => i.sku),
          contents: productos.map((i: any) => { return {
            'id': i.sku,
            'quantity': i.cantidad ? parseInt(i.cantidad, 10) : 1
          }}),
          currency: 'ARS',
          num_items: productos.length,
          value: total,
        }

        // console.log('FacebookPixel: sending "InitiateCheckout" fbq event. Payload:', payload)

        fbq('track', 'InitiateCheckout', payload)
      } catch (error) {
        console.log('FacebookPixel: error sending "InitiateCheckout" fbq event.', error)
      }
    }
  }

  public async purchase(idpedido: any, total: any, productos: any) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          content_ids: productos.map((i: any) => i.sku),
          contents: productos.map((i: any) => { return {
            'id': i.sku,
            'quantity': i.cantidad ? parseInt(i.cantidad, 10) : 1
          }}),
          content_name: 'checkout',
          content_type: 'product',
          currency: 'ARS',
          num_items: productos.length,
          value: parseFloat(total),
        }

        // console.log('FacebookPixel: sending "Purchase" fbq event. Payload:', payload)

        fbq('track', 'Purchase', payload)
      } catch (error) {
        console.log('FacebookPixel: error sending "Purchase" fbq event.', error)
      }
    }
  }

  public async search(term: string) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          search_string: term
        }

        // console.log('FacebookPixel: sending "Search" fbq event. Payload:', payload)

        fbq('track', 'Search', payload);
      } catch (error) {
        console.log('FacebookPixel: error sending "Search" fbq event.', error)
      }
    }
  }
}
