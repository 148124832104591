import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

declare const ga: (a?: any, b?: any, c?: any) => void;
declare const gtag: (a?: any, b?: any, c?: any) => void;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }
  }

  // Nuevos eventos GA4

  /* public async signUp() {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          send_to: "G-77NZ1Z0DYE",
          method: "Google"
        }

        // console.log('GoogleAnalytics: sending "sign_up" GA4 event. Payload:', payload)

        gtag("event", "sign_up", payload);
      } catch (error) {
        console.log('GoogleAnalytics: error sending "sign_up" GA4 event.', error)
      }
    }
  } */

  public async registroExitoso() {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          send_to: "G-77NZ1Z0DYE",
          event_category: 'registro',
          event_label: 'registro'
        }

        // console.log('GoogleAnalytics: sending "exitoso" GA4 event. Payload:', payload)

        gtag('event', 'exitoso', payload);
      } catch (error) {
        console.log('GoogleAnalytics: error sending "exitoso" GA4 event.', error)
      }
    }
  }

  /* public async pageView(page_title: string, page_location: string) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          send_to: "G-77NZ1Z0DYE",
          page_title: page_title,
          page_location: page_location
        }

        // console.log('GoogleAnalytics: sending "page_view" GA4 event. Payload:', payload)

        gtag('event', 'page_view', payload);
      } catch (error) {
        console.log('GoogleAnalytics: error sending "page_view" GA4 event.', error)
      }
    }
  } */

  public async viewItem(item: any) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          send_to: "G-77NZ1Z0DYE",
          currency: "ARS",
          value: item.precio && item.cantidad ? parseFloat(item.precio) * parseInt(item.cantidad, 10) : undefined,
          items: [
            {
              item_id: item.sku,
              item_name: item.descripcion,
              affiliation: "SINA",
              item_brand: "SINA",
              item_category: item.categoria,
              price: item.precio ? parseFloat(item.precio) : undefined,
              quantity: item.cantidad ? parseInt(item.cantidad, 10) : 1,
              // coupon: "SUMMER_FUN",
              // discount: 2.22,
              // index: 0,
              // item_category2: "Adult",
              // item_category3: "Shirts",
              // item_category4: "Crew",
              // item_category5: "Short sleeve",
              // item_list_id: "related_products",
              // item_list_name: "Related Products",
              // item_variant: "green",
              // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
            }
          ]
        }

        // console.log('GoogleAnalytics: sending "view_item" GA4 event. Payload:', payload)

        gtag("event", "view_item", payload);
      } catch (error) {
        console.log('GoogleAnalytics: error sending "view_item" GA4 event.', error)
      }
    }
  }

  public async addToCart(item: any) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          send_to: "G-77NZ1Z0DYE",
          currency: "ARS",
          value: parseFloat(item.precio) * parseInt(item.cantidad, 10),
          items: [
            {
              item_id: item.sku,
              item_name: item.descripcion,
              affiliation: "SINA",
              item_brand: "SINA",
              item_category: item.categoria,
              price: parseFloat(item.precio),
              quantity: parseInt(item.cantidad, 10),
              // coupon: "SUMMER_FUN",
              // discount: 2.22,
              // index: 0,
              // item_category2: "Adult",
              // item_category3: "Shirts",
              // item_category4: "Crew",
              // item_category5: "Short sleeve",
              // item_list_id: "related_products",
              // item_list_name: "Related Products",
              // item_variant: "green",
              // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
            }
          ]
        }

        // console.log('GoogleAnalytics: sending "add_to_cart" GA4 event. Payload:', payload)

        gtag("event", "add_to_cart", payload)
      } catch (error) {
        console.log('GoogleAnalytics: error sending "add_to_cart" GA4 event.', error)
      }
    }
  }

  public async removeFromCart(item: any) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          send_to: "G-77NZ1Z0DYE",
          currency: "ARS",
          value: parseFloat(item.precio) * parseInt(item.cantidad, 10),
          items: [
            {
              item_id: item.sku,
              item_name: item.descripcion,
              affiliation: "SINA",
              item_brand: "SINA",
              item_category: item.categoria,
              price: parseFloat(item.precio),
              quantity: parseInt(item.cantidad, 10),
              // coupon: "SUMMER_FUN",
              // discount: 2.22,
              // index: 0,
              // item_category2: "Adult",
              // item_category3: "Shirts",
              // item_category4: "Crew",
              // item_category5: "Short sleeve",
              // item_list_id: "related_products",
              // item_list_name: "Related Products",
              // item_variant: "green",
              // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
            }
          ]
        }

        // console.log('GoogleAnalytics: sending "remove_from_cart" GA4 event. Payload:', payload)

        gtag("event", "remove_from_cart", payload)
      } catch (error) {
        console.log('GoogleAnalytics: error sending "remove_from_cart" GA4 event.', error)
      }
    }
  }

  public async beginCheckout(productos: any) {
    if (this.clientOnlyRun) {
      try {
        let total = 0
        for (const i of productos) {
          total += parseFloat(i.precio) * parseInt(i.cantidad, 10)
        }

        const payload = {
          send_to: "G-77NZ1Z0DYE",
          value: total,
          currency: "ARS",
          // tax: 4.90,
          // shipping: 5.99,
          // coupon: "SUMMER_SALE",
          items: productos.map((i: any) => { return {
            item_id: i.sku,
            item_name: i.descripcion,
            affiliation: "SINA",
            item_brand: "SINA",
            item_category: i.categoria,
            price: parseFloat(i.precio),
            quantity: parseInt(i.cantidad, 10),
            // coupon: "SUMMER_SALE",
            // discount: 2.22,
            // index: 0,
            // item_category2: "Adult",
            // item_category3: "Shirts",
            // item_category4: "Crew",
            // item_category5: "Short sleeve",
            // item_list_id: "related_products",
            // item_list_name: "Related Products",
            // item_variant: "green",
            // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          }}),
        }

        // console.log('GoogleAnalytics: sending "begin_checkout" GA4 event. Payload:', payload)

        gtag("event", "begin_checkout", payload)
      } catch (error) {
        console.log('GoogleAnalytics: error sending "begin_checkout" GA4 event.', error)
      }
    }
  }

  public async purchase(idpedido: any, total: any, productos: any) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          send_to: "G-77NZ1Z0DYE",
          transaction_id: idpedido,
          value: parseFloat(total),
          currency: "ARS",
          // tax: 4.90,
          // shipping: 5.99,
          // coupon: "SUMMER_SALE",
          items: productos.map((i: any) => { return {
            item_id: i.sku,
            item_name: i.descripcion,
            affiliation: "SINA",
            item_brand: "SINA",
            item_category: i.categoria,
            price: parseFloat(i.precio),
            quantity: parseInt(i.cantidad, 10),
            // coupon: "SUMMER_SALE",
            // discount: 2.22,
            // index: 0,
            // item_category2: "Adult",
            // item_category3: "Shirts",
            // item_category4: "Crew",
            // item_category5: "Short sleeve",
            // item_list_id: "related_products",
            // item_list_name: "Related Products",
            // item_variant: "green",
            // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          }}),
        }

        // console.log('GoogleAnalytics: sending "purchase" GA4 event. Payload:', payload)

        gtag("event", "purchase", payload)
      } catch (error) {
        console.log('GoogleAnalytics: error sending "purchase" GA4 event.', error)
      }
    }
  }

  public async search(term: string) {
    if (this.clientOnlyRun) {
      try {
        const payload = {
          send_to: "G-77NZ1Z0DYE",
          search_term: term
        }

        // console.log('GoogleAnalytics: sending "search" GA4 event. Payload:', payload)

        gtag("event", "search", payload);
      } catch (error) {
        console.log('GoogleAnalytics: error sending "search" GA4 event.', error)
      }
    }
  }
}
