import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuService } from '../menu.service';
import { SharedService } from '../shared.service';

export class Link {
  active!: boolean;
  url!:    string;
  texto!:  string;
  urlImg?: string;
}
const LINKS: Link[] = [
  { active: true, url: 'nosotros',   texto: 'Nosotros' },
  { active: true, url: 'envios',   texto: 'Envíos' },
  { active: true, url: 'como-comprar',   texto: 'Como comprar' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  LinkList: any = [];
  MenuList: any = [];
  LinkIndex = 0;
  LinkIndexHija = 0;
  UserLog!: boolean;
  SideStateIcon!: string;
  UserName: any;
  UserJob: any;
  LinkListSubMenu: any = LINKS;

  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  constructor(
    private menu: MenuService,
    private router: Router,
    private data: SharedService,
    // private http: HttpClient,
    // private auth: AutenticacionService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }

    this.menu.LinkList$.pipe(takeUntil(this.destroy$)).subscribe(($cambio_link: any) => {
      if ($cambio_link) {
        this.LinkList = $cambio_link;
      }

    });
    this.menu.MenuList$.pipe(takeUntil(this.destroy$)).subscribe(($cambio_menu: any) => {
      if ($cambio_menu) {
        this.MenuList = $cambio_menu;
      }
    });
  }
  ngOnInit() {
    // subscribing to data on carritoStatus
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.UserLog = status;
        if (this.data.user) {
          try {
            this.UserName = this.data.user.razonSocial;
            this.UserJob  = this.data.user.categoriaIva;
          }catch (e) {
            this.data.log('oninit error sidebar:', e);
          }
        }
      },
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  convertLink = ($subcategoria: any) => {
    try {
      const texto = $subcategoria.nombre.split(' ').join('-') + '/' + $subcategoria.id;
      return texto;
    } catch ($error) {
      this.data.log('Alguno de los datos de la subcategoria esta incompleto error sidebar');
      return '';
    }
  }
  convertLink2= ($categoria: any, $subcategoria: any) => {
    try {
      const texto = $categoria.nombre.split(' ').join('-')  + '/' +  $subcategoria.nombre.split(' ').join('-') + '/' + $subcategoria.id;
      return texto;
    } catch ($error) {
      this.data.log('Alguno de los datos de la subcategoria esta incompleto 2 error sidebar');
      return '';
    }
  }

  toggleMenu() {
    this.data.toggleSideBar();
  }
  changeStyleClick($index: any) {
    if (this.LinkIndex !== $index) {
      this.LinkIndex = $index;
    } else {
      this.LinkIndex = 0;
    }
  }
  changeStyleClickHija($index: any, $seccion: any) {
    if ($seccion.items.length) {
      if (this.LinkIndexHija !== $index) {
        this.LinkIndexHija = $index;
      } else {
        this.LinkIndexHija = 0;
      }
    } else {
      this.data.toggleSideBar();
    }
  }
  closeHija() {
    this.LinkIndexHija = 0;
  }
  registrar() {
    this.data.toggleLoginModal();
    this.data.toggleSideBar();
  }
  toggleSideBar() {
    this.data.toggleSideBar();
  }
  sidebarCerrarSesion() {
    this.toggleSideBar();
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['/cuenta'], { queryParams: {tab: 4}}));
  }

}
