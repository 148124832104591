<div class="row message">
  <!-- Client only code -->
  <div class="container">
    <ngb-alert *ngIf="successMessage && clientOnlyRun" (click)="alertClicked()" type="success" (close)="alertClicked();">
      <i class="fa fa-check-circle fa-3x" aria-hidden="true"></i> {{ successMessage }}
        <button class="actionButton transparentButton" [routerLink]="['/compra/carrito']">VER TODOS</button>
    </ngb-alert>
  </div>
</div>
<div class="container producto">
  <!-- Client only code -->
  <div class="producto__header" style="position: relative;" *ngIf="clientOnlyRun">
    <h1 [ngStyle]="{'max-width': producto.oferta == false && producto.novedad == false ? 'unset' : 'calc(100% - 60px)' }">{{producto["titulo"] + ', ' + producto["titulo"] + ' al por mayor'}}</h1>
  </div>
  <!-- Server only code -->
  <div class="producto__header" style="position: relative;" *ngIf="serverOnlyRun">
    <h1 style="max-width: unset;">{{tituloProducto}}</h1>
  </div>
  <br>
  <div class="row">
    <div class="badges">
      <div *ngIf="producto.oferta == true" class="oferta" [ngClass]="{'resultado__item__disabled': producto.enStock == '0' && producto.ventaSoloPorPedido == '0'}">
        <h3 class="badge">OFERTA DEL MES</h3>
      </div>
      <!-- Novedades -->
      <div *ngIf="producto.novedad == true" class="imperdible" [ngClass]="{'resultado__item__disabled': producto.enStock == '0' && producto.ventaSoloPorPedido == '0'}">
        <h3 class="badge">NOVEDAD</h3>
      </div>
    </div>
    <!-- Client only code -->
    <div class="col-12 col-md-6 imagen__visor" style="max-height: 300px; display: flex;justify-content: center;" *ngIf="clientOnlyRun">

      <img width="450" height="300" style="max-width: 100%; height: max-content;" [ngClass]="'lazyload'" [attr.data-src]="producto['urlImagen']" onerror="this.src='/assets/images/producto/picGenerica.webp'" alt="">
      <div *ngIf="producto.habilitado == '0' || (producto.enStock == '0' && producto.ventaSoloPorPedido == '0')" class="sinStock">
        <img width="75" height="41" [ngClass]="'lazyload'" data-src="./assets/images/iconos/sin-stock.webp" alt="Sin stock">
      </div>
      <div *ngIf="producto.habilitado == '1' && producto.ventaSoloPorPedido == '1'" class="porPedido">
        <img width="75" height="41" [ngClass]="'lazyload'" data-src="./assets/images/iconos/por-pedido.webp" alt="Por pedido">
      </div>
      <div *ngIf="producto.habilitado == '1' && producto.sustentable == '1'" class="productoSustentable">
        <img width="75" height="76" [ngClass]="'lazyload'" data-src="./assets/images/iconos/sustentable2.webp" alt="Sustentable">
      </div>
    </div>
    <!-- Server only code -->
    <div class="col-12 col-md-6 imagen__visor" style="max-height: 300px; display: flex;" *ngIf="serverOnlyRun">
      <!-- Loading spinner -->
      <div class="spinner">
        <div class="dot1"></div>
        <div class="dot2"></div>
      </div>
    </div>
    <div class="col-12 col-md-6 producto__descripcion">
      <!-- Client only code -->
      <div class="title" *ngIf="clientOnlyRun">
        <p>{{producto['titulo']}} - {{producto['tituloAdicional']}}</p>
        <div class="social">
          <i [shareButton]="'facebook'" [url]="full_link" class="fa fa-facebook" aria-hidden="true"></i>
          <i [shareButton]="'twitter'"  [url]="full_link" class="fa fa-twitter"  aria-hidden="true"></i>
        </div>
      </div>
      <!-- Server only code -->
      <div class="title" *ngIf="serverOnlyRun">
        <p>Cargando...</p>
        <div class="social">
          <i [shareButton]="'facebook'" [url]="full_link" class="fa fa-facebook" aria-hidden="true"></i>
          <i [shareButton]="'twitter'"  [url]="full_link" class="fa fa-twitter"  aria-hidden="true"></i>
        </div>
      </div>
      <div class="row codigo">
        <p>Código: {{producto['codInterno']}}</p>
        <p style="margin-bottom: 0px;">Código de barras: {{producto['codBarra']}}</p>
        <p>Presentación: {{producto["unidadMedida"]}}</p>
      </div>
      <div *ngIf="config.switchComprasActivo" class="precio__container justify-content-start mx-2">
        <div class="row producto__precio gap-1 gap-lg-3" *ngIf="producto.precio">
          <div class="titled div-oferta text-danger" *ngIf="producto.oferta == true && producto.show_descuento && producto.descuento">{{ producto.descuento }}% OFF</div>
          <div class="titled">
            <p class="m-0">${{ producto.precio | number : '.2' }}</p>
          </div>
          <div class="titled fw-light precio-oferta" *ngIf="producto.oferta && producto.precio_mostrado > 0 && producto.precio_base > 0 && producto.precio_base > producto.precio">
            <p class="m-0">${{ producto.precio_base | number : '.2' }}</p>
          </div>
          <div class="precio__container__mobile" *ngIf="producto.precio && iva_usuario">
            <p>{{iva_usuario}}</p>
          </div>
        </div>
        <div style="position: relative;"class="row producto__cartel" *ngIf="producto.precio && iva_usuario">
          <p class="p-0">{{iva_usuario}}</p>
        </div>
      </div>
      <div *ngIf="config.switchComprasActivo" class="d-flex flex-md-wrap gap-2 button">
        <div class="row producto__compra col-md-12 m-0 w-auto">
          <div class="cantidad p-0">
            <p style="margin-right: 10px; margin-bottom: 0;">Cantidad:</p>
            <input *ngIf="producto.cantPack === '1'" name="cantidad" aria-label="Cantidad" (keyup.enter)="enterCheck()" type="number" min="1" max="999999" [(ngModel)]="producto.cantidad" (keyup)="revisarCantidad($event)" [disabled]="producto.habilitado == '0' || (producto.enStock == '0' && producto.ventaSoloPorPedido == '0')">
            <select *ngIf="producto.cantPack !== '1'" name="cantidad" aria-label="Cantidad" [(ngModel)]="producto.cantidad" [disabled]="producto.habilitado == '0' || (producto.enStock == '0' && producto.ventaSoloPorPedido == '0')">
              <option *ngFor="let cant of arrayCants" [value]="cant">{{cant}}</option>
            </select>
          </div>
        </div>
        <button class="agregar actionButton w-auto m-0" *ngIf="producto.habilitado == '1' && producto.comprado == false && (producto.enStock == '1' || producto.ventaSoloPorPedido == '1')" (click)="newMessage(producto)">AGREGAR AL CARRITO</button>
        <button class="bloqButton w-auto m-0" *ngIf="producto.habilitado == '0' || (producto.comprado == false && producto.enStock == '0' && producto.ventaSoloPorPedido == '0')">SIN STOCK</button>
        <button class="agregar actionButton btn-outline-success w-auto m-0" *ngIf="producto.comprado == true" (click)="removeMessage(producto)">AGREGADO</button>
      </div>
    </div>
  </div>
  <div class="row producto__relacionados pt-3 px-3 justify-content-center">
    <div class="p-0">
      <h2 class="wiggle fs-1">Artículos relacionados</h2>
    </div>
    <!-- Client only code -->
    <div class="row resultado px-0" *ngIf="clientOnlyRun">
      <div class="resultado__item col-6 col-md-3" *ngFor="let item of relacionados | myfilter:{id: null}">
        <app-producto-item [item]="item" [relacionado]="true" [sesion]="false" [mensaje]="_success"></app-producto-item>
      </div>
    </div>
    <!-- Server only code -->
    <div class="row resultado px-0" *ngIf="serverOnlyRun">
      <div class="resultado__item col-6 col-md-3" *ngFor="let item of [0,1,2,3]">
        <!-- Loading spinner -->
        <div class="spinner">
          <div class="dot1"></div>
          <div class="dot2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
