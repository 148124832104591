<section class="pre_footer my-3">
  <div class="container text-center">
    <p>
      En Sina ofrecemos los mejores artículos de {{ cat }} al por mayor y
      los productos de bazar y limpieza de fabricación nacional y comprometida con el medio ambiente.
      <br>
      Somos una empresa líder en fabricación y distribución mayorista de artículos de
      limpieza y accesorios; riego; bazar; y líneas de limpieza profesional e institucional.
      {{ cat !== 'limpieza' ? (cat1 ? ('Entregamos artículos de '+cat) : (cat2 ? ('Además todos los meses contamos con ofertas exclusivas y entregamos '+cat) : 'Entregamos')) : 'Entregamos' }} en todo el país (GBA e interior) con camiones propios y tercerizados,
      estamos preparados para brindar el mejor servicio de asesoramiento, venta y postventa del mercado.
    </p>
  </div>
</section>
<div class="fondoFooter">
  <div class="container footer">
    <div class="row showAlways">
      <div class="col-lg-4 col-12 footer__section" *ngIf="_existDesktop">
        <div class="header">SINA</div>
        <div class="content">
          <p><i class="fa fa-envelope" aria-hidden="true"></i> Email: <a href="mailto:infoweb@sina.com.ar" style="text-transform: none;">infoweb@sina.com.ar</a></p>
          <p><i class="fa fa-phone" aria-hidden="true"></i> Teléfono: <a href="tel:01121399100">011 2139-9100</a></p>
          <p><i class="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp: <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5491153073468">+54 9 11 5307-3468</a></p>
          <p><i class="fa fa-clock-o" aria-hidden="true"></i> Horario de atención: Lun. a Vie. de 8 a 12 hs. y de 14 a 17 hs. Sáb. de 8 a 12 hs.</p>
          <p><i class="fa fa-location-arrow" aria-hidden="true"></i> Domicilio: <a href="https://g.page/Sina-messina-hnos?share" target="_blank" rel="noopener noreferrer">Madres de Plaza de Mayo 949 (1878), Quilmes, Bs. As.</a></p>
        </div>
      </div>
      <div class="col-lg-8 col-12">
        <div class="row">
            <div class="col-lg-2 col-6 footer__section" *ngIf="_existDesktop">
              <div class="header">CONTENIDO</div>
              <div class="content">
                <ul>
                  <li *ngFor="let item of LinkList" class="nav-item">
                    <a class="nav-link m-0 p-0" routerLink="{{item.url}}" routerLinkActive="active">{{item.texto}} <span class="sr-only">current</span></a>
                  </li>
                  <li class="nav-item" *ngIf="!data.statusLogin">
                    <a style="cursor: pointer" class="nav-link m-0 p-0" (click)="registrar()">Nuevo cliente</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link m-0 p-0" routerLink="preguntas-frecuentes" routerLinkActive="active">Preguntas frecuentes<span class="sr-only">current</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link m-0 p-0" routerLink="contacto" routerLinkActive="active">Contacto<span class="sr-only">current</span></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-12 footer__section social">
                <div class="header">SEGUINOS</div>
                <div class="content">
                  <ul class="ulSocial">
                    <li *ngFor="let item of SocialList" class="nav-item" [ngClass]="{'active': item.active}">
                      <a class="nav-link m-0 p-0" href="{{item.url}}" target="_blank" rel="noopener noreferrer">
                        <img width="18" height="18" [ngClass]="'lazyload'" [attr.data-src]="item.urlImg" title="{{item.texto}}" />
                        <p>
                        {{item.texto}}
                        </p>
                        <span class="sr-only">current</span>
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
            <div class="col-lg-4 col-12 footer__section">
                <div class="header">NEWSLETTER / SUSCRIBITE</div>
                <div class="content">
                  <input type="text" name="nombre" aria-label="Newsletter Nombre" placeholder="Nombre" [(ngModel)]="nombre" [disabled]="!enableSuscribir">
                  <input type="text" name="email" aria-label="Newsletter Email" placeholder="Email" [(ngModel)]="email" [disabled]="!enableSuscribir">
                  <button class="whiteButton" (click)="suscribir()" type="button" [disabled]="!enableSuscribir">{{suscripcion}}</button>
                </div>
            </div>
            <div class="col-lg-4 col-12 footer__section">
                <div class="header">SUMATE A SINA</div>
                <div class="content">
                  <p>Si estás interesado en formar parte de nuestro Staff envianos tus datos y adjuntá tu CV.</p>
                  <a class="whiteButton" routerLink="sumate" routerLinkActive="active">SUMARME</a>
                </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row showMobile" *ngIf="_existMobile">
      <div class="col-12 footer__section">
        <div class="header">SINA</div>
        <div class="content">
          <p><i class="fa fa-envelope" aria-hidden="true"></i> Email: <a href="mailto:infoweb@sina.com.ar" style="text-transform: none;">infoweb@sina.com.ar</a></p>
          <p><i class="fa fa-phone" aria-hidden="true"></i> Teléfono: <a href="tel:01121399100">011 2139-9100</a></p>
          <p><i class="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp: <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5491153073468">+54 9 11 5307-3468</a></p>
          <p><i class="fa fa-clock-o" aria-hidden="true"></i> Horario de atención: Lun. a Vie. de 8 a 12 hs. y de 14 a 17 hs. Sáb. de 8 a 12 hs.</p>
          <p><i class="fa fa-location-arrow" aria-hidden="true"></i> Domicilio: <a href="https://g.page/Sina-messina-hnos?share" target="_blank" rel="noopener noreferrer">Madres de Plaza de Mayo 949 (1878), Quilmes, Bs. As.</a></p>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
            <div class="col-lg-2 col-12 footer__section">
              <div class="header">CONTENIDO</div>
              <div class="content">
                <ul>
                  <li *ngFor="let item of LinkList" class="nav-item">
                    <a class="nav-link m-0 p-0" routerLink="{{item.url}}" routerLinkActive="active">{{item.texto}} <span class="sr-only">current</span></a>
                  </li>
                  <li class="nav-item" *ngIf="!data.statusLogin">
                    <a style="cursor: pointer" class="nav-link m-0 p-0" (click)="registrar()">Nuevo cliente</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link m-0 p-0" routerLink="preguntas-frecuentes" routerLinkActive="active">Preguntas frecuentes<span class="sr-only">current</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link m-0 p-0" routerLink="contacto" routerLinkActive="active">Contacto<span class="sr-only">current</span></a>
                  </li>
                </ul>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-between" style="padding-bottom: 50px;">
      <div class="col-md-12" style="width: 70%; display: inline-block;">
        <img width="111" height="34" [ngClass]="'lazyload'" data-src="./assets/images/header/logo.webp" alt="Sina">
        <p>
          Copyright © {{year}} Sina. Todos los derechos reservados. - <a routerLink="politica-privacidad">Política de Privacidad</a><br>
          <a href="https://www.leren.com.ar/?utm_source=footer&utm_medium=credits_web&utm_campaign=www.sina.com.ar" target="_blank" rel="noopener noreferrer">
            <img width="163" height="18" [ngClass]="'lazyload'" data-src="./assets/images/footer/leren.svg" alt="Leren" style="margin-top: 10px;">
          </a>
        </p>
        <p style="font-size: 11px;">
          <a href="https://growon.agency/">Agencia de Marketing</a>
        </p>
        <p style="font-size: 11px;">
          <a href="https://www.viajestodoincluido.com.ar/">Agencia de Viajes</a>
        </p>
      </div>
      <div style="position: relative; text-align: right; float: right; width: auto; height: 84px;">
        <a href="http://qr.afip.gob.ar/?qr=BIt7TM_d2TcdF7mMgQjHOw,," target="_blank" rel="noopener noreferrer">
          <img width="44" height="60" [ngClass]="'lazyload'" data-src="./assets/images/footer/AFIP_DATA_FISCAL.webp" alt="AFIP" style="margin-top: 12px;">
        </a>
      </div>
    </div>
  </div>
</div>
