import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { SharedService } from '../shared.service';

export class Link {
  active!: boolean;
  url!:    string;
  texto!:  string;
  urlImg?: string;
}
const LINKS: Link[] = [
  { active: true, url: '',   texto: 'Inicio' },
  { active: true, url: 'nosotros',   texto: 'Nosotros' },
  { active: true, url: 'envios',   texto: 'Envíos' },
  { active: true, url: 'como-comprar',   texto: 'Como comprar' },
];

const SOCIAL: Link[] = [
  { active: false, url: 'https://www.facebook.com/Sina.Argentina', texto: 'Facebook', urlImg: '/assets/images/iconos/facebook.png' },
  { active: false, url: 'https://www.instagram.com/sina.argentina/', texto: 'Instagram', urlImg: '/assets/images/iconos/instagram.png' },
  // { active: false, url: 'https://www.twitter.com/sina_argentina', texto: 'Twitter', urlImg: '/assets/images/iconos/twitter.png' },
  { active: false, url: 'https://www.youtube.com/channel/UCXeHXMr9oWN-d-mHPU9VRuQ', texto: 'Youtube', urlImg: '/assets/images/iconos/youtube.png' },
  { active: false, url: 'https://www.tiktok.com/@sina.argentina', texto: 'TikTok', urlImg: '/assets/images/iconos/tiktok.png' },
]

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private navigationEnd: any;
  private routePathParam: any;
  private subRoutePathParam: any;
  LinkList: any = LINKS;
  SocialList: any = SOCIAL;
  nombre!: string;
  email!: string;
  suscripcion: string = 'SUSCRIBIRME';
  enableSuscribir: boolean = true;
  _existDesktop: boolean = false;
  _existMobile: boolean = false;
  year: number;

  cat: string = 'limpieza';
  cat1: boolean = false;
  cat2: boolean = false;

  private clientOnlyRun: boolean = false;
  private serverOnlyRun: boolean = false;

  constructor(
    public data: SharedService,
    private auth: AutenticacionService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (typeof window !== typeof undefined) {
      if (window.innerWidth <= 992) {
        this._existDesktop = false;
        this._existMobile = true;
      } else {
        this._existDesktop = true;
        this._existMobile = false;
      }
    } else {
      this._existDesktop = true;
      this._existMobile = true;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }
    this.year = new Date().getFullYear();
  }

  ngOnInit() {
    if (this.clientOnlyRun) {
      this.router.events.pipe(filter(event => event instanceof ActivationEnd)).subscribe(
        (activationEnd: any) => {
          this.cat = 'limpieza';
          this.cat1 = false;
          this.cat2 = false;
          const params = activationEnd.snapshot.params;
          const url = activationEnd.snapshot.url;
          if (params && params.id && params.id2) {
            this.cat = params.id.split('-').join(' ');
            this.cat1 = false;
            this.cat2 = true;
          } else {
            if (url && url.length === 1) {
              const familias = [
                'Limpieza',
                'Bazar',
                'Textil',
                'Liquidos',
                'Jardin y riego',
                'Profesional',
                'Mas productos',
              ];

              if (familias.includes(url[0].path)) {
                this.cat = url[0].path;
                this.cat1 = true;
                this.cat2 = false;
              }
            }
          }
        }
      );
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
  }

  registrar() {
    this.data.toggleLoginModal();
  }
  suscribir() {
    this.enableSuscribir = false;

    if (this.nombre && this.email) {

      let resultado = 'OK';

      if (environment.production && environment.APP_MAILING_SUBSCRIBE) {
        const body = new URLSearchParams();
        body.set('api_key', 'RCUuYdks5u0kwkTgCVlw ');
        body.set('name', this.nombre);
        body.set('email', this.email);
        body.set('list', 'cqW4SwUCeaOE36rhy8922C3A');
        body.set('country', 'AR');
        body.set('referrer', environment.APP_WEB_BASE);
        body.set('boolean', 'true');

        if (this.data.statusLogin) {
          body.set('logueado', 'SI');

          this.auth.get('sendy/cliente/getDatosNewsletter')
          .then((result) => {
            this.data.log('response getDatosNewsletter footer', result);

            body.set('lista_precios', result.response.lista_precios);
            body.set('tipo_lista', result.response.tipo_lista);
            body.set('perfil', result.response.perfil);

            this.auth.post(environment.APP_MAILING_BASE + 'subscribe', body)
            .then(($response) => {
              this.data.log('response suscribir footer', $response);
            })
            .catch(($error) => {
              this.data.log('error suscribir footer', $error);
              resultado = $error.error.text;
            });
          })
          .catch((error) => {
            this.data.log('error getDatosNewsletter footer', error);

            this.auth.post(environment.APP_MAILING_BASE + 'subscribe', body)
            .then(($response) => {
              this.data.log('response suscribir footer', $response);
            })
            .catch(($error) => {
              this.data.log('error suscribir footer', $error);
              resultado = $error.error.text;
            });
          });
        } else {
          body.set('logueado', 'NO');

          this.auth.post(environment.APP_MAILING_BASE + 'subscribe', body)
          .then(($response) => {
            this.data.log('response suscribir footer', $response);
          })
          .catch(($error) => {
            this.data.log('error suscribir footer', $error);
            resultado = $error.error.text;
          });
        }
      }

      this.suscripcion = 'ENVIANDO.';
      setTimeout(() => {
      this.suscripcion = 'ENVIANDO..';
      setTimeout(() => {
      this.suscripcion = 'ENVIANDO...';
      setTimeout(() => {
      this.suscripcion = resultado === 'Already subscribed.' ? 'YA ESTÁS SUSCRITO/A!' : 'ENVIADO!';
      setTimeout(() => {
        this.email = '';
        this.nombre = '';
        // this.suscripcion = 'SUSCRIBIRME';
        // this.enableSuscribir = true;
      }, 500);
      }, 500);
      }, 500);
      }, 1000);
    } else {
      this.suscripcion = 'CAMPOS INCOMPLETOS!';
      setTimeout(() => {
        this.suscripcion = 'SUSCRIBIRME';
        this.enableSuscribir = true;
      }, 3000);
    }

  }
}
